import React, { useContext, useEffect, useState } from 'react';
import Filter from '../../Shared/Filter';
import Modal from '../../Shared/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import searchIcon from '../../../assets/img/search-tc-icon.svg';
import filterIcon from '../../../assets/img/filter.png';
import excelIcon from '../../../assets/img/export-dark-icon.png';
import { ErrorContext } from '../../../context/errorContext';
import { getMyInstallationsData, getAllTCs, tagTC } from '../../../services/api/billingService';
import FullScreenLoader from '../../Shared/FullScreenLoader';
import NoDataFoundRow from '../../Shared/NoDataFoundRow';
import { mrCode } from '../../../assets/constants';
import Ellipsis from '../Ellipsis';

const Table = ({updateCount}) => {

  const errorContext = useContext(ErrorContext)

  const [data, setData] = useState([])
  const [tcList, setTCList] = useState([])
  const [transformer, setTransformer] = useState({ number: "", name: "" }) //For tc-tagging modal's radio-button 
  const [installationSelected,setInstallationSelected] = useState("")
  const [startDate, setStartDate] = useState(new Date());
  const [filterVisible, setFilterVisible] = useState(false);
  const [modalObject, setModalObject] = useState({
    type: 'export',
    title: 'Select file type',
    modalVisible: false
  });
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    fetchInstallationsAndTCData()
    // fetchAllTCs()
  }, [])

  const fetchInstallationsAndTCData = async () => {
    try {
      setLoading(true)
      const response = await Promise.all([getMyInstallationsData(), getAllTCs()])
      setData(response[0].data)
      setTCList(response[1].data)
      setLoading(false)
    }
    catch (err) {
      errorContext.showError(err)
      setLoading(false)
    }

  }

  const filterHandler = (event) => {
    event.preventDefault();
    setFilterVisible(!filterVisible);
  }

  const applyFilters = (filters) => {
    console.log(filters);
  }

  const tcSelectionHandler = (transformerId) => {
    setTransformer(transformerId)
  }

  const handleTCTagging = async () => {
    try{
      setLoading(true)
      await tagTC(installationSelected.installationId,transformer)
      fetchInstallationsAndTCData()
      updateCount()
      setLoading(false)
    }
    catch(err){
      errorContext.showError(err)
      setLoading(false)
    }

  }

  const modalHandler = (event) => {
    event.preventDefault();
    setModalObject({
      type: 'export',
      title: 'Select file format',
      modalVisible: !modalObject.modalVisible
    });
  }

  const tagModalHandler = (event) => {
    event.preventDefault();
    setModalObject({
      type: 'tag',
      title: 'Tag TC',
      modalVisible: true
    });
  }

  return (
    <React.Fragment>
      <Filter enabledFilters={{ durations: false, tariffs: true, tags: true, remarks: false }} isFilterVisible={filterVisible} onFilterClose={filterHandler} onFiltersApply={applyFilters} />
      <Modal
        data={tcList.filter(item=>item.readingDay===installationSelected.readingDay)}
        value={transformer}
        onChange={tcSelectionHandler}
        title={modalObject.title}
        type={modalObject.type}
        isModalVisible={modalObject.modalVisible}
        onModalClose={modalHandler}
        onTagTC={handleTCTagging}
      />
      {loading ? <FullScreenLoader />
        :
        <div className="col-md-9">
          <div className="row mt-35">
            <div className="col-md-12">
              <div className="comman-head">
                <div className="installation-title">
                  <h1>My Installations</h1>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="main-tc-name-form mb-3">
                <div className="search-form">
                  <form>
                    <div className="forms-input">
                      <div className="row">
                        <div className="col-12 col-md-5 col-lg-5">
                          <input className="form-control" type="text" placeholder="Enter MR Code" value={mrCode} disabled/>
                        </div>
                        <div className="col-12 col-md-4 col-lg-5 reading-day-input">
                          <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                          {/* <input className="form-control" type="text" placeholder="Reading Day" /> */}
                        </div>
                        <div className="col-12 col-md-3 col-lg-2">
                          <button type="submit" style={{ marginTop: '0px' }} className="btn btn-primary">Search</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="list-view-box">
                      <div className="search-heading">
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <div className="search-space">
                              <input type="text" placeholder="Search" className="form-control search" />
                            </div>
                          </div>
                          <div class="col-12 col-md-6 text-right">
                            <div className="export-filter">
                              <a onClick={modalHandler} className="export-btn">
                                <img className="fa img-fluid" src={excelIcon} />&nbsp; <span>Export to Excel</span>
                              </a>
                              <button onClick={filterHandler} type="button" className="btn btn-primary filter-btn">
                                <img src={filterIcon} className="" width="18" height="12" />Filter
                            </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="pl-15" scope="col">#</th>
                              <th scope="col">MR Code</th>
                              <th scope="col">Reading Day</th>
                              <th scope="col">Account ID</th>
                              <th scope="col">RR Number</th>
                              <th scope="col">Tariff</th>
                              <th scope="col">Consumer Name</th>
                              <th scope="col">Sanction Load</th>
                              <th scope="col">TC Number</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {
                              data.length === 0 ? <NoDataFoundRow message="No Installations found" /> :
                                data.map((item, index) => (
                                  <tr key={index}>
                                    <td className="pl-15" scope="row">{index + 1}</td>
                                    <td>{mrCode}</td>
                                    <td style={{ textAlign: 'center' }}>{item.readingDay}</td>
                                    <td>{item.accountNumber}</td>
                                    <td>{item.rrNumber}</td>
                                    <td>{item.tariff}</td>
                                    <Ellipsis>
                                      {item.consumerName}
                                    </Ellipsis>
                                    <td>{item.sanctionedLoad.kW} KW</td>
                                    <td>
                                      {'transformer' in item ?
                                        <span className="inactive">TC-{item.transformer.number}</span>
                                        : <a href="#"><span className="active" onClick={(e) => { tagModalHandler(e); setInstallationSelected(item) }}>Tag-TC</span></a>
                                      }
                                    </td>
                                  </tr>
                                ))}
                            {/* <tr>
                          <td className="pl-15" scope="row">2</td>
                          <td>123456</td>
                          <td>1</td>
                          <td>1234567890</td>
                          <td>4EEH1234</td>
                          <td>LT 3</td>
                          <td>Ganesh Kharatmal</td>
                          <td>2 KW</td>
                          <td><span className="inactive">TC-235</span></td>
                        </tr>
                        <tr>
                          <td className="pl-15" scope="row">3</td>
                          <td>123456</td>
                          <td>1</td>
                          <td>1234567890</td>
                          <td>4EEH1234</td>
                          <td>LT 3</td>
                          <td>Ganesh Kharatmal</td>
                          <td>2 KW</td>
                          <td><span className="inactive">TC-235</span></td>
                        </tr>
                        <tr>
                          <td className="pl-15" scope="row">4</td>
                          <td>123456</td>
                          <td>1</td>
                          <td>1234567890</td>
                          <td>4EEH1234</td>
                          <td>LT 3</td>
                          <td>Ganesh Kharatmal</td>
                          <td>2 KW</td>
                          <td><span className="inactive">TC-235</span></td>
                        </tr>
                        <tr>
                          <td className="pl-15" scope="row">5</td>
                          <td>123456</td>
                          <td>1</td>
                          <td>1234567890</td>
                          <td>4EEH1234</td>
                          <td>LT 3</td>
                          <td>Ganesh Kharatmal</td>
                          <td>2 KW</td>
                          <td><span className="inactive">TC-235</span></td>
                        </tr>
                        <tr>
                          <td className="pl-15" scope="row">6</td>
                          <td>123456</td>
                          <td>1</td>
                          <td>1234567890</td>
                          <td>4EEH1234</td>
                          <td>LT 3</td>
                          <td>Ganesh Kharatmal</td>
                          <td>2 KW</td>
                          <td><span className="inactive">TC-235</span></td>
                        </tr> */}
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <nav className="float-end" aria-label="Page navigation example">
                <ul className="pagination mb-0">
                  <li className="page-item next-item"><a className="page-link border-0" href="#">Previous</a></li>
                  <li className="page-item"><a className="page-link" href="#">1</a></li>
                  <li className="page-item previous-item"><span>Out of 100</span><a className="page-link border-0 active" href="#">Next</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>}
    </React.Fragment>
  );
}

export default Table;