import React, { Fragment, useState } from "react";
import closeIcon from "../../../assets/img/close-icon.png";
import gpsIcon from "../../../assets/img/gpc-location.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelect from "react-multi-select-component";
import Select from 'react-select';

const AddTC = (props) => {
  const [startDate, setStartDate] = useState("");
  const [activeScreen, setActiveScreen] = useState("add_tc_form");
  const [selected, setSelected] = useState([]);

  const options = [
    { label: "MR 123", value: "mr123" },
    { label: "MR 345", value: "mr345" },
    { label: "MR 768", value: "mr768" },
    { label: "MR 908", value: "mr908" },
    { label: "MR 876", value: "mr876" },
    { label: "MR 934", value: "mr934" }
  ];

  var option = [
    { value: 'F14', label: 'F14' },
    { value: 'F10', label: 'F10' },
  ];
  function logChange(val) {
    console.log("Selected: " + val);
  }

  const tcConfirmScreenHandler = (event) => {
    setActiveScreen("confirm_tc_form");
  };

  const tcFormShowHandler = (event) => {
    setActiveScreen("add_tc_form");
  };

  const tcFormSubmitHandler = (event) => {
    event.preventDefault();
    alert("Submit Handler");
  };

  const onCloseButtonHandler = (event) => {
    props.onCloseButton();
  };

  return (
    <Fragment>
      <div className="row add-tc-info">
        <div className="col-md-12 mb-3 mt-3">
          <div className="code-section">
            <span>Energy Audit &nbsp;|</span>
            <span className="add-new-tc-text"><a href="#">&nbsp; Add New TC</a>
            </span>
          </div>
          <hr className="horizontal-line" />
        </div>
      </div>

      <div className="row add-tc-info">
        <div className="col-md-6">
          <div className="add-tc-title">
            <h1 className="mb-0">Add New TC</h1>
          </div>
        </div>
        <div className="col-md-6">
          <div className="close-mr">
            <button onClick={onCloseButtonHandler}
              type="button" className="btn btn-primary">
              <img src={closeIcon} />
              Close
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-4">
          {activeScreen == "add_tc_form" && (
            <div className="transformer-detail-form p-3 mb-3">
              <form>
                <div className="forms-input">
                  <div className="row custom-row">
                    <div className="col-md-12 mb-3 mt-2">
                      <div className="x-former-detail">
                        <h3 className="mb-2">Transformer Details</h3>
                      </div>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">Select MR Code</label>
                      <MultiSelect
                        options={options}
                        value={selected}
                        onChange={setSelected}
                        disableSearch="true"
                        placeholderText='Select MR Code'
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">TC Reading MR</label>
                      <div className="reading-radio-btn">
                        <div className="form-check mb-0 me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault1"
                          >
                            MR 1234
                          </label>
                        </div>
                        <div className="form-check mb-0 me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault1"
                          >
                            MR 3456
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">Reading Day</label>
                      <select className="form-select" aria-label="Reading Day">
                        <option selected="">Reading Day</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">Reading Day</label>
                      <select className="form-select" aria-label="Reading Day">
                        <option selected="">Reading Day</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">TC Name</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="TC Name"
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">TC Serial Number</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="TC Serial Number"
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">TC Capacity</label>
                      <select className="form-select" aria-label="TC Capacity">
                        <option selected="">TC Capacity</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">Tims Code</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Tims Code"
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">DTLMS</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="DTLMS"
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">DTR</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="DTR"
                      />
                    </div>
                    <div className="col-md-4 mb-4 position-relative">
                      <label className="form-label font-regular-400">GPS Location</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="GPS Location"
                      />
                      <img className="img-fluid fa-compass" src={gpsIcon} />
                      {/* <i className="fa fa-compass" aria-hidden="true"></i> */}
                    </div>
                    <div className="col-md-4 mb-4 position-relative feeder-name-select">
                      <label className="form-label font-regular-400">Select Feeder Name</label>
                      <Select
                        name="form-field-name"
                        options={option}
                        onChange={logChange}
                        placeholder="Select Feeder Name"
                      />
                    </div>
                    <hr className="divider" />
                    <div className="col-md-12 mb-3 mt-2">
                      <div className="x-former-detail">
                        <h3 className="mb-2">Meter Details</h3>
                      </div>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">Make</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Make"
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">Serial Number</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Serial Number"
                      />
                    </div>
                    <div className="col-md-4 mb-4"></div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">Meter Constant</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Meter Constant"
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">CT Ratio</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="CT Ratio"
                      />
                    </div>
                    <hr className="divider" />
                    <div className="col-md-12 mb-3 mt-2">
                      <div className="x-former-detail">
                        <h3 className="mb-2">Initial Reading</h3>
                      </div>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">Select Month</label>
                      <DatePicker
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        showThreeColumnMonthYearPicker
                        placeholderText="Select"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label font-regular-400">Reading</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Reading"
                      />
                    </div>
                    <div className="col-md-4 mb-4"></div>
                    <hr className="divider" />
                    <div className="col-md-12 mb-3 mt-2">
                      <div className="x-former-detail">
                        <h3 className="mb-2">Final Reading</h3>
                      </div>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label">Select Month</label>
                      <DatePicker
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        showThreeColumnMonthYearPicker
                        placeholderText="Select"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label">Reading</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Reading"
                      />
                    </div>
                    <div className="col-md-4 mb-4"></div>
                    <hr className="divider" />
                    <div className="col-md-12 mt-3 text-end tc-submit-btn">
                      <button
                        onClick={tcConfirmScreenHandler}
                        type="submit"
                        className="btn btn-primary custom-active"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
          {activeScreen == "confirm_tc_form" && (
            <div className="transformer-detail-form p-3 mb-3">
              <div className="row">
                <div className="col-md-12 mb-2 mt-2">
                  <div className="x-former-detail">
                    <h3>Transformer Details</h3>
                  </div>
                </div>
                <div className="col-md-12 mb-3 mt-2">
                  <div className="energy-audit-view">
                    <ul className="ps-2">
                      <li>
                        <p className="heading mb-0">Select MR Code</p>
                        <p>MR 12345, MR 34567</p>
                      </li>
                      <li>
                        <p className="heading mb-0">TC Reading MR</p>
                        <p>MR 12345</p>
                      </li>
                      <li>
                        <p className="heading mb-0">Reading Day</p>
                        <p>2</p>
                      </li>
                      <li>
                        <p className="heading mb-0">TC Number</p>
                        <p>TC 1234</p>
                      </li>
                      <li>
                        <p className="heading mb-0">TC Name</p>
                        <p>Rajajinagar 4th block</p>
                      </li>
                      <li>
                        <p className="heading mb-0">TC Serial Number</p>
                        <p>123456</p>
                      </li>
                      <li>
                        <p className="heading mb-0">TC Capacity</p>
                        <p>63 KVA</p>
                      </li>
                      <li>
                        <p className="heading mb-0">Tims Code</p>
                        <p>1234567890</p>
                      </li>
                      <li>
                        <p className="heading mb-0">DTLMS</p>
                        <p>12345</p>
                      </li>
                      <li>
                        <p className="heading mb-0">DTR</p>
                        <p>123456</p>
                      </li>
                      <li>
                        <p className="heading mb-0">GPS Location</p>
                        <p>Rajajinagar</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr className="divider" />
                <div className="col-md-12 mb-2 mt-2">
                  <div className="x-former-detail">
                    <h3>Meter Details</h3>
                  </div>
                </div>
                <div className="col-md-12 mb-3 mt-2">
                  <div className="energy-audit-view">
                    <ul className="ps-2">
                      <li>
                        <p className="heading mb-0">GPS Location</p>
                        <p>Rajajinagar</p>
                      </li>
                      <li>
                        <p className="heading mb-0">Make</p>
                        <p>12345</p>
                      </li>
                      <li>
                        <p className="heading mb-0">Serial Number</p>
                        <p>123456</p>
                      </li>
                      <li>
                        <p className="heading mb-0">Meter Constant</p>
                        <p>123456</p>
                      </li>
                      <li>
                        <p className="heading mb-0">CT Ratio</p>
                        <p>12345</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr className="divider" />
                <div className="col-md-12 mb-2 mt-2">
                  <div className="x-former-detail">
                    <h3>Initial Reading</h3>
                  </div>
                </div>
                <div className="col-md-12 mb-3 mt-2">
                  <div className="energy-audit-view">
                    <ul className="ps-2">
                      <li>
                        <p className="heading mb-0">Select Month</p>
                        <p>01/12/2020</p>
                      </li>
                      <li>
                        <p className="heading mb-0">Reading</p>
                        <p>123456</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr className="divider" />
                <div className="col-md-12 mb-2 mt-2">
                  <div className="x-former-detail">
                    <h3>Final Reading</h3>
                  </div>
                </div>
                <div className="col-md-12 mb-3 mt-2">
                  <div className="energy-audit-view">
                    <ul className="ps-2">
                      <li>
                        <p className="heading mb-0">Select Month</p>
                        <p>01/12/2020</p>
                      </li>
                      <li>
                        <p className="heading mb-0">Reading</p>
                        <p>123456</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr className="divider" />
                <div className="col-md-12">
                  <div className="text-end tc-edit-confirm-btn">
                    <button onClick={tcFormShowHandler} className="btn btn-primary edit-btn me-3">
                      Edit
                    </button>
                    <button
                      onClick={tcFormSubmitHandler}
                      type="submit"
                      className="btn btn-primary confirm-btn"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default AddTC;
