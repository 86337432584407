import React, { Fragment, useContext, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import filterImage from '../../assets/img/filter.png';
import filterIcon from '../../assets/img/filter.png';
import downloadImage from '../../assets/img/file_download-24px.png';
import UploadImage from '../../assets/img/upload-icon@2x.png';
import ViewImage from '../../assets/img/view-selected-icon@2x.png';
import downIcon from '../../assets/img/other-main-down-arrow.svg';
import fileIcon from '../../assets/img/file-icon.svg';
import checkIcon from '../../assets/img/step-blue-circle-selected.svg';
import errorCheckIcon from '../../assets/img/step-error-icon.svg';
import successTick from '../../assets/img/successful-small-cricle-icon.svg';
import errorTick from '../../assets/img/error-red-small-circle-icon.svg';
import upIcon from '../../assets/img/other-main-up-arrow.svg';
import searchIcon from '../../assets/img/search-tc-icon.svg';
import { Link, useHistory } from 'react-router-dom';
import Filter from '../Shared/Filter';
import { uploadDCB, uploadDoorLock, uploadInstallations, uploadMNR, uploadTCDetails, uploadUnbilled, uploadVacant, uploadZeroConsumption } from '../../services/api/uploadService';
import { ErrorContext } from '../../context/errorContext';
import { colors, mrCode } from '../../assets/constants';
import { getAuditResult, runAudit } from '../../services/api/tcAuditService';
import { getAllTCs } from '../../services/api/tcService';
import NoDataFoundRow from '../Shared/NoDataFoundRow';
import FullScreenLoader from '../Shared/FullScreenLoader';

export default function TCAudit() {

  const errorContext = useContext(ErrorContext)
  const history = useHistory()

  const displayStyle = {
    display: "-webkit-inline-box",
  };

  const transformersArray = [
    {
      mr_code: '123456',
      reading_day: 1,
      tc_no: 'TC 125',
      tc_name: 'Rajajinagar',
      installations: '540',
      tc_consumtion: '540 Units',
      installation_consumtion: '540 Units',
      loss: '3 %'
    },
    {
      mr_code: '123456',
      reading_day: 1,
      tc_no: 'TC 125',
      tc_name: 'Rajajinagar',
      installations: '540',
      tc_consumtion: '540 Units',
      installation_consumtion: '540 Units',
      loss: '3 %'
    },
    {
      mr_code: '123456',
      reading_day: 1,
      tc_no: 'TC 125',
      tc_name: 'Rajajinagar',
      installations: '540',
      tc_consumtion: '540 Units',
      installation_consumtion: '540 Units',
      loss: '3 %'
    },
    {
      mr_code: '123456',
      reading_day: 1,
      tc_no: 'TC 125',
      tc_name: 'Rajajinagar',
      installations: '540',
      tc_consumtion: '540 Units',
      installation_consumtion: '540 Units',
      loss: '3 %'
    },
    {
      mr_code: '123456',
      reading_day: 1,
      tc_no: 'TC 125',
      tc_name: 'Rajajinagar',
      installations: '540',
      tc_consumtion: '540 Units',
      installation_consumtion: '540 Units',
      loss: '3 %'
    },
    {
      mr_code: '123456',
      reading_day: 1,
      tc_no: 'TC 125',
      tc_name: 'Rajajinagar',
      installations: '540',
      tc_consumtion: '540 Units',
      installation_consumtion: '540 Units',
      loss: '21 %'
    },
    {
      mr_code: '123456',
      reading_day: 1,
      tc_no: 'TC 125',
      tc_name: 'Rajajinagar',
      installations: '540',
      tc_consumtion: '540 Units',
      installation_consumtion: '540 Units',
      loss: '80 %'
    },
    {
      mr_code: '123456',
      reading_day: 1,
      tc_no: 'TC 125',
      tc_name: 'Rajajinagar',
      installations: '540',
      tc_consumtion: '540 Units',
      installation_consumtion: '540 Units',
      loss: '10 %'
    }
  ];

  const [allTransformers, setAllTransformers] = useState([])
  const [transformers, setTransformers] = useState(transformersArray);
  const [auditedTransformers, setAuditedTransformers] = useState(transformersArray);
  const [unauditedTransformers, setUnauditedTransformers] = useState(transformersArray);
  const [auditResult, setAuditResult] = useState({});
  const [executionType, setExecutionType] = useState('');
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    fetchAuditResult()
    fetchAllTransformers()
  }, [executionType])

  const fetchAuditResult = async () => {
    try {
      setLoading(true)
      const response = await getAuditResult(executionType)
      setAuditResult(response.data)
      setLoading(false)
    }
    catch (err) {
      errorContext.showError(err)
      setLoading(false)
    }
  }

  const fetchAllTransformers = async () => {
    try {
      const response = await getAllTCs()
      setAllTransformers(response.data)
      setTransformers(response.data.filter(item => executionType ? item.executionType === executionType : item))
      setAuditedTransformers(response.data.filter(item => (executionType ? (item.executionType === executionType && item.audit.auditStatus === true) : item.audit.auditStatus === true)))
      setUnauditedTransformers(response.data.filter(item => (executionType ? (item.executionType === executionType && item.audit.auditStatus === false) : item.audit.auditStatus === false)))


    }
    catch (err) {
      errorContext.showError(err)
    }
  }


  const filesForMonthArray = [
    {
      title: "TC Details",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      isUploaded: false,
      isValidated: false,
      isProcessed: false,
      isUploadFailed: false,
      isValidationFailed: false,
      isProcessingFailed: false,
      fileBrowsed: false,
      downloadSampleFile: true,
      uploadFunction: uploadTCDetails
    },
    {
      title: "Installation Status Report",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      isUploaded: false,
      isValidated: false,
      isProcessed: false,
      isUploadFailed: false,
      isValidationFailed: false,
      isProcessingFailed: false,
      fileBrowsed: false,
      downloadSampleFile: false,
      uploadFunction: uploadInstallations

    },
    {
      title: "TC - RR Number Tagging",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      isUploaded: false,
      isValidated: false,
      isProcessed: false,
      isUploadFailed: false,
      isValidationFailed: false,
      isProcessingFailed: false,
      fileBrowsed: false,
      downloadSampleFile: true,
      uploadFunction: uploadInstallations
    },
    {
      title: "Unbilled Report",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      isUploaded: false,
      isValidated: false,
      isProcessed: false,
      isUploadFailed: false,
      isValidationFailed: false,
      isProcessingFailed: false,
      fileBrowsed: false,
      downloadSampleFile: false,
      uploadFunction: uploadUnbilled

    },
    {
      title: "MNR Report",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      isUploaded: false,
      isValidated: false,
      isProcessed: false,
      isUploadFailed: false,
      isValidationFailed: false,
      isProcessingFailed: false,
      fileBrowsed: false,
      downloadSampleFile: false,
      uploadFunction: uploadMNR

    },
    {
      title: "Vacant  Report",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      isUploaded: false,
      isValidated: false,
      isProcessed: false,
      isUploadFailed: false,
      isValidationFailed: false,
      isProcessingFailed: false,
      fileBrowsed: false,
      downloadSampleFile: false,
      uploadFunction: uploadVacant

    },
    {
      title: "Doorlock Report",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      isUploaded: false,
      isValidated: false,
      isProcessed: false,
      isUploadFailed: false,
      isValidationFailed: false,
      isProcessingFailed: false,
      fileBrowsed: false,
      downloadSampleFile: false,
      uploadFunction: uploadDoorLock

    },
    {
      title: "Zero Consumption",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      isUploaded: false,
      isValidated: false,
      isProcessed: false,
      isUploadFailed: false,
      isValidationFailed: false,
      isProcessingFailed: false,
      fileBrowsed: false,
      downloadSampleFile: false,
      uploadFunction: uploadZeroConsumption
    },
    // {
    //   title: "Zero Consumption Report",
    //   description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   isUploaded: false,
    //   isValidated: false,
    //   isProcessed: false,
    //   isUploadFailed: false,
    //   isValidationFailed: false,
    //   isProcessingFailed: false,
    //   fileBrowsed: false,
    //   downloadSampleFile: false

    // },
    {
      title: "Demand Consumption Balance (DCB) Report",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      isUploaded: false,
      isValidated: false,
      isProcessed: false,
      isUploadFailed: false,
      isValidationFailed: false,
      isProcessingFailed: false,
      fileBrowsed: false,
      downloadSampleFile: false,
      uploadFunction: uploadDCB
    }
  ];

  const [filesForMonth, setFilesForMonth] = useState(filesForMonthArray);
  const [activeUpload, setActiveUpload] = useState('');
  const [activeItem, setActiveItem] = useState('');
  const [activeDataTab, setDataTab] = useState('total-transformers');
  const [fileBeingUploaded, setFileBeingUploaded] = useState(0);

  const [activeTransformerAuditedButton, setActiveTransformerAuditedButton] = useState('');
  const [activeTransformerUnauditedButton, setActiveTransformerUnauditedButton] = useState('');
  const [filterVisible, setFilterVisible] = useState(false);

  //component did update for filtering audited transformers 
  useEffect(() => {
    let transformersArrayLocal = [...allTransformers.filter(item => (executionType ? (item.executionType === executionType && item.audit.auditStatus === true) : item.audit.auditStatus === true))];
    if (activeTransformerAuditedButton === "negative-loss") {
      setAuditedTransformers(transformersArrayLocal.filter(item => item.audit.percentageLoss < 0))
      return
    }
    if (activeTransformerAuditedButton === "zero-six") {
      setAuditedTransformers(transformersArrayLocal.filter(item => item.audit.percentageLoss >= 0 && item.audit.percentageLoss <= 5))
      return
    }
    if (activeTransformerAuditedButton === "six-ten") {
      setAuditedTransformers(transformersArrayLocal.filter(item => item.audit.percentageLoss >= 6 && item.audit.percentageLoss <= 10))
      return
    }
    if (activeTransformerAuditedButton === "ten-fifteen") {
      setAuditedTransformers(transformersArrayLocal.filter(item => item.audit.percentageLoss >= 11 && item.audit.percentageLoss <= 15))
      return
    }
    if (activeTransformerAuditedButton === "fifteen-twenty") {
      setAuditedTransformers(transformersArrayLocal.filter(item => item.audit.percentageLoss >= 16 && item.audit.percentageLoss <= 20))
      return
    }
    if (activeTransformerAuditedButton === "above-twenty") {
      setAuditedTransformers(transformersArrayLocal.filter(item => item.audit.percentageLoss > 20))
      return
    }
    if (activeTransformerAuditedButton === '') {
      setAuditedTransformers(transformersArrayLocal)
      return
    }
  }, [activeTransformerAuditedButton])

  const auditedTransformersButtonHandler = (tag) => {
    setActiveTransformerAuditedButton(prevState => prevState === tag ? '' : tag);
  }

  //component did update for filtering unaudited transformers 
  useEffect(() => {
    let transformersArrayLocal = [...allTransformers.filter(item => (executionType ? (item.executionType === executionType && item.audit.auditStatus === false) : item.audit.auditStatus === false))];
    if (activeTransformerUnauditedButton === "tc-intagged") {
      setUnauditedTransformers(transformersArrayLocal.filter(item => item.number === "151" || item.number === "150"))
      return
    }
    if (activeTransformerUnauditedButton === "tc-without-fr") {
      setUnauditedTransformers(transformersArrayLocal.filter(item => item.number === "209"))
      return
    }
    if (activeTransformerUnauditedButton === "tc-with-remarks") {
      setUnauditedTransformers([])
      return
    }
    if (activeTransformerUnauditedButton === '') {
      setUnauditedTransformers(transformersArrayLocal)
      return
    }
  }, [activeTransformerUnauditedButton])

  const unauditedTransformersButtonHandler = (tag) => {
    setActiveTransformerUnauditedButton(prevState => prevState === tag ? '' : tag);
    // let transformersArrayLocal = [...transformersArray];

    // setTransformers(transformersArrayLocal.slice(0, randomNumber));
  }

  const accordianHandler = (event) => {
    event.preventDefault();
    var key = event.currentTarget.getAttribute('data-panel');
    if (activeItem == key) {
      setActiveItem('');
    } else {
      setActiveItem(key);
    }
  }

  const onResultTabHandler = (tab) => {
    setDataTab(tab);
  }

  const fileBrowserHandler = async (event, file) => {
    try {
      const response = await file.uploadFunction()
      setActiveUpload(event.target.dataset.title);
      const filesForMonthClone = [...filesForMonth];
      setTimeout(() => {
        filesForMonthClone.forEach(element => {
          if (element.title === event.target.dataset.title) {
            element.isUploaded = true;
            element.isValidated = true;
            element.isProcessed = true;
          }
        });
        setActiveUpload(event.target.dataset.title);
        setFilesForMonth(filesForMonthClone);
        setFileBeingUploaded(fileBeingUploaded + 1);
      }, 500);
    }
    catch (err) {
      errorContext.showError(err)
    }

  }

  const startAuditHandler = async () => {
    try {
      const response = await runAudit()
      setActiveItem("audit-result-panel")
      fetchAuditResult()
      fetchAllTransformers()

    }
    catch (err) {
      errorContext.showError(err)
    }
  }

  const [startDate, setStartDate] = useState('');

  const filterHandler = () => {
    setFilterVisible(!filterVisible);
  }

  const applyFilters = (filters) => {
    console.log(filters);
  }

  return (
    <Fragment>
      <Filter value={executionType} onChange={(val) => { setExecutionType(val) }} onApply={filterHandler} onReset={() => { setExecutionType("") }} isFilterVisible={filterVisible} onFilterClose={filterHandler} />
      <div className="tc-audit-file-management-content">
        <div className="container">
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="comman-head">
                <div className="management-title">
                  <h1>TC Audit</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="tc-audit-file-management-list">
                <div className="tc-audit-file-list mb-3">
                  <div className="substation-collapse tc-audit-file-active">
                    <Link onClick={accordianHandler} data-panel="audit-panel" className="collapse-text" role="button" aria-expanded="false" aria-controls="collapseAuditFile-1">
                      <div className="tc-audit-collpase-title">
                        <p className="mb-0 me-auto sub-station-title font-medium-500">TC Audit Files</p>
                      </div>
                      <img className="img-fluid" className="fa fa-angle-down" src={downIcon} />
                      <img className="img-fluid" className="fa fa-angle-up" src={upIcon} />
                    </Link>
                  </div>
                  <div className={`collapse collapse-detail ${activeItem == 'audit-panel' ? 'show' : ''}`} id="collapseAuditFile-1">
                    <div className="card border-0">
                      {
                        filesForMonth.map((fileType, index) => {
                          return (
                            <div key={index} className="card-body mb-3">
                              <div className="tc-top-detail row">
                                <div className="tc-details-left col-12 col-md-8 col-xl-10">
                                  <p className="mb-2 tc-title">{fileType.title}</p>
                                  <p className="mb-2">{fileType.description}</p>
                                  {
                                    fileType.downloadSampleFile &&
                                    (<span>
                                      <a href="#" style={displayStyle}>
                                        <img className="fa fa-file-text img-fluid" src={fileIcon} />&nbsp;
                                        <p className="mb-0 download-link">Download sample file</p>
                                      </a>
                                    </span>)
                                  }
                                </div>
                                <div className="tc-upload-btn col-12 col-md-4 col-xl-2">
                                  {
                                    index == fileBeingUploaded &&
                                    (<input
                                      data-title={fileType.title}
                                      className="form-control"
                                      // type="file"
                                      type={index === 0 ? "file" : "submit"}
                                      onClick={(e) => fileBrowserHandler(e, fileType)}
                                      onChange={(e) => fileBrowserHandler(e, fileType)} />)
                                  }
                                  <button className={`btn btn-primary ${index == fileBeingUploaded ? 'upload-load-active' : ''}`}>
                                    {/* <i className="fa fa-upload" aria-hidden="true"></i> */}
                                    <img className="img-fluid icon-width-height-24" src={UploadImage} /> Upload
                                  </button>
                                  {/* <button href="#" className="btn btn-primary upload-load-active justify-content-center">
                                    <i className="fa fa-undo" aria-hidden="true"></i>
                                  </button> */}
                                </div>
                              </div>

                              {/* /////////////////Upload Status Start////////////////// */}
                              <div className={`${activeUpload === fileType.title ? '' : 'd-none'}`}>
                                <ul className="tc-progress-bar mt-4">
                                  <li>
                                    <span>
                                      <p className={`mb-0 ${fileType.isUploaded ? 'check-icon' : ''} ${fileType.isUploadFailed ? 'cross-icon' : ''}`}>
                                        {!fileType.isUploaded && <span>1</span>}
                                        {fileType.isUploaded && <img className="fa fa-check img-fluid" src={checkIcon} />}
                                        {fileType.isUploadFailed && <img className="fa fa-times img-fluid" src={errorCheckIcon} />}
                                      </p>
                                    </span>
                                    <p className="mb-0 text-muted mt-1">Uploaded</p>
                                  </li>
                                  <hr className={`${fileType.isUploaded ? 'activated' : ''}`} />
                                  <li>
                                    <span>
                                      <p className={`mb-0 ${fileType.isValidated ? 'check-icon' : ''} ${fileType.isValidationFailed ? 'cross-icon' : ''}`}>
                                        {!fileType.isValidated && <span>2</span>}
                                        {fileType.isUploaded && fileType.isValidated && <img className="fa fa-check img-fluid" src={checkIcon} />}
                                        {fileType.isUploaded && fileType.isValidationFailed && <img className="fa fa-times img-fluid" src={errorCheckIcon} />}
                                      </p>
                                    </span>
                                    <p className="mb-0 text-muted mt-1">Validated</p>
                                  </li>
                                  <hr className={`${fileType.isUploaded && fileType.isValidated && fileType.isProcessed ? 'activated' : ''}`} />
                                  <li>
                                    <span>
                                      <p className={`mb-0 ${fileType.isUploaded && fileType.isValidated && fileType.isProcessed ? 'check-icon' : ''} ${fileType.isValidationFailed ? 'cross-icon' : ''}`}>
                                        {!fileType.isProcessed && <span>3</span>}
                                        {fileType.isUploaded && fileType.isValidated && fileType.isProcessed && <img className="fa fa-check img-fluid" src={checkIcon} />}
                                        {fileType.isProcessingFailed && <img className="fa fa-times img-fluid" src={errorCheckIcon} />}
                                      </p>
                                    </span>
                                    <p className="mb-0 text-muted mt-1">Processed</p>
                                  </li>
                                </ul>
                                {/* <p className="view-success-screen">
                                  <a href="#">View Processing Logs</a>
                                </p> */}
                                {/* <p className="error-msg"><img className="fa fa-exclamation-circle" width="20" src={errorTick} />&nbsp; Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</p> */}
                                <p className="success-msg mb-0"><img className="fa fa-check-square" width="20" src={successTick} />&nbsp; File Processed Successfully</p>
                                <p className="result-title">Result</p>
                                <p className="mb-2 success-title"><img className="fa fa-check-square" src={successTick} /> <span className="font-bold">Success:</span> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</p>
                                {/* <p className="mb-2 error-title"><img className="fa fa-exclamation-circle" src={errorTick} /> <span className="font-bold">Failure:</span> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</p> */}
                              </div>
                              {/* /////////////////Upload Status End////////////////// */}
                            </div>
                          )
                        })
                      }
                      <div className="start-audit-btn mt-3 me-3 text-end" >
                        <button
                          className="btn btn-primary"
                          style={{ background: colors.primary, borderColor: colors.primary, width: 125 }}
                          disabled={activeUpload !== 'Demand Consumption Balance (DCB) Report'}
                          onClick={startAuditHandler}>
                          Start Audit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* //////////////////////////////////////AUDIT RESULT///////////////////////////////////// */}
                <div className="tc-audit-file-list audit-result-list mb-3">
                  <div className="substation-collapse tc-audit-file-active p-3">
                    <a href="#" onClick={accordianHandler} data-panel="audit-result-panel" className="collapse-text" role="button" aria-expanded="false" aria-controls="collapseAuditFile-2">
                      <div className="tc-audit-collpase-title">
                        <p className="mb-0 me-auto sub-station-title font-medium-500">Audit Result</p>
                      </div>
                      <img className="fa fa-angle-down" src={downIcon} />
                      <img className="fa fa-angle-up" src={upIcon} />
                    </a>
                  </div>
                  {loading ? <FullScreenLoader /> : !auditResult ? <div style={{ display: "flex", flex: 1, alignItems: 'center', justifyContent: 'center', padding: 20 }}>No Audit Result</div>
                    :
                    <div className={`collapse collapse-detail ${activeItem == 'audit-result-panel' ? 'show' : ''}`} id="collapseAuditFile-2">
                      <div className="container audit-result-section">
                        <div className="row">
                          <div className="col-md-12">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                              <li className="nav-item me-2" role="presentation">
                                <button onClick={() => onResultTabHandler('total-transformers')} className={`nav-link ${activeDataTab == 'total-transformers' ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                  <p className="mb-0 font-medium-500">Total Transformers</p>
                                  <hr />
                                  <h4 className="mb-0">{auditResult.totalTransformers}</h4>
                                </button>
                              </li>
                              <li className="nav-item me-2" role="presentation">
                                <button onClick={() => onResultTabHandler('transformers-audited')} className={`nav-link ${activeDataTab == 'transformers-audited' ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                  <p className="mb-0 font-medium-500">Transformers Audited</p>
                                  <hr />
                                  <h4 className="mb-0">{auditResult.auditedCount}</h4>
                                </button>
                              </li>
                              <li className="nav-item me-2" role="presentation">
                                <button onClick={() => onResultTabHandler('transformers-unaudited')} className={`nav-link ${activeDataTab == 'transformers-unaudited' ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">
                                  <p className="mb-0 font-medium-500">Transformers Unaudited</p>
                                  <hr />
                                  <h4 className="mb-0">{auditResult.unAudited}</h4>
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-xl-12 col-lg-12 col-md-9">
                            <div className="tab-content" id="myTabContent">
                              <div className={`tab-pane fade show ${activeDataTab == 'total-transformers' ? 'show active' : ''} first-toggle-tab mb-4`} role="tabpanel" aria-labelledby="home-tab">
                                <div className="row top-transformer-tab">
                                  <div className="col-md-12">
                                    <div className="main-tc-table list-view-box ">
                                      <div className="search-heading">
                                        <div className="search-space position-relative me-auto">
                                          <input type="text" placeholder="Search by TC Number or Name" className="form-control" />
                                          <img className="img-fluid fa" src={searchIcon} />
                                        </div>
                                        <div className="download-result">
                                          <a href="#" className="download-bt me-3">
                                            <img src={downloadImage} className="me-2" />
                                            <p className="mb-0 font-regular-400">Download Result</p>
                                          </a>
                                          <button onClick={filterHandler} type="button" className="btn btn-primary filter-btn">
                                            <img src={filterIcon} className="" width="18" height="12" />Filter
                                          </button>
                                        </div>
                                      </div>

                                      <div class="table-responsive">
                                        <table className="table mt-0">
                                          <thead>
                                            <tr>
                                              <th className="ps-4" scope="col">#</th>
                                              <th scope="col">MR Code</th>
                                              <th scope="col">Reading Day</th>
                                              <th scope="col">TC No</th>
                                              <th scope="col">TC Name</th>
                                              <th scope="col">Installations</th>
                                              <th scope="col">TC Consumption</th>
                                              <th scope="col">Installations Consumption</th>
                                              <th scope="col">% Loss</th>
                                              <th scope="col">View</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              transformers.length === 0 ? <NoDataFoundRow /> :
                                                transformers.map((transformer, index) => {
                                                  return (
                                                    <tr>
                                                      <td className="ps-4" scope="row">{index + 1}</td>
                                                      <td>{mrCode}</td>
                                                      <td>{transformer.readingDay}</td>
                                                      <td>{transformer.number}</td>
                                                      <td>{transformer.name}</td>
                                                      <td>{transformer.installationCount}</td>
                                                      <td>{transformer?.latestReading?.consumption ?? "N/A"}</td>
                                                      <td>{transformer?.audit?.installationConsumption} Units</td>
                                                      <td> {transformer?.audit?.auditStatus ? `${transformer?.currentLoss?.value}%` : "----"}</td>
                                                      <td>
                                                        <div style={{ cursor: 'pointer' }} onClick={() => history.push({
                                                          pathname: `/tc-audit/${index + 1}`,
                                                          search: `?status=${transformer?.audit?.auditStatus ? 'audited' : 'unaudited'}`,
                                                          state: {
                                                            transformerData: transformer
                                                          }
                                                        })}>
                                                          <img className="img-fluid icon-width-height" src={ViewImage} />
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  )
                                                })
                                            }
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <nav aria-label="Page navigation example" className="pagination-1 pt-3 pb-3">
                                      <ul className="pagination mb-0">
                                        <li className="page-item next-item"><a className="page-link border-0" href="#">Previous</a></li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item previous-item"><span>Out of 100</span><a className="page-link border-0 active" href="#">Next</a></li>
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                              <div className={`tab-pane fade second-toggle-tab mb-4 ${activeDataTab == 'transformers-audited' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="profile-tab">
                                <div className="row top-transformer-tab">
                                  <div className="col-md-4">
                                    <div className="card custom-card-tc-4">
                                      <div className={`cp card-body ${activeTransformerAuditedButton == 'negative-loss' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('negative-loss')}>
                                        <p className="card-text mb-0 me-auto font-regular-400">Negative Loss</p>
                                        <hr />
                                        <h4 className="mb-0">{auditResult.negativeLoss}</h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="card custom-card-tc-5">
                                      <div className={`cp card-body ${activeTransformerAuditedButton == 'zero-six' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('zero-six')}>
                                        <p className="card-text mb-0 me-auto font-regular-400">0 - 5%</p>
                                        <hr />
                                        <h4 className="mb-0">{auditResult.zeroToFivePercent}</h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="card custom-card-tc-6">
                                      <div className={`cp card-body ${activeTransformerAuditedButton == 'six-ten' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('six-ten')}>
                                        <p className="card-text mb-0 me-auto font-regular-400">5 - 10%</p>
                                        <hr />
                                        <h4 className="mb-0">{auditResult.fiveToTenPercent}</h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="card custom-card-tc-7">
                                      <div className={`cp card-body ${activeTransformerAuditedButton == 'ten-fifteen' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('ten-fifteen')}>
                                        <p className="card-text mb-0 me-auto font-regular-400">10 - 15%</p>
                                        <hr />
                                        <h4 className="mb-0">{auditResult.tenToFifteenPercent}</h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="card custom-card-tc-8">
                                      <div className={`cp card-body ${activeTransformerAuditedButton == 'fifteen-twenty' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('fifteen-twenty')}>
                                        <p className="card-text mb-0 me-auto font-regular-400">15 - 20%</p>
                                        <hr />
                                        <h4 className="mb-0">{auditResult.fifteenToTwentyPercent}</h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="card custom-card-tc-8">
                                      <div className={`cp card-body ${activeTransformerAuditedButton == 'above-twenty' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('above-twenty')}>
                                        <p className="card-text mb-0 me-auto font-regular-400">Above 20%</p>
                                        <hr />
                                        <h4 className="mb-0">{auditResult.twentyAndAbovePercent}</h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="main-tc-table mt-4 list-view-box">
                                      <div className="search-heading">
                                        <div className="search-space position-relative me-auto">
                                          <input type="text" placeholder="Search by TC Number or Name" className="form-control" />
                                          <img className="img-fluid fa" src={searchIcon} />
                                        </div>
                                        <div className="download-result">
                                          <a href="#" className="download-bt me-3">
                                            <img src={downloadImage} className="me-2" />
                                            <p className="mb-0 font-regular-400">Download Result</p>
                                          </a>
                                          <button onClick={filterHandler} type="button" className="btn btn-primary filter-btn">
                                            <img src={filterIcon} className="" width="18" height="12" />Filter
                                          </button>
                                        </div>
                                      </div>

                                      <div className="table-responsive">
                                        <table className="table mt-0">
                                          <thead>
                                            <tr>
                                              <th className="ps-4" scope="col">#</th>
                                              <th scope="col">MR Code</th>
                                              <th scope="col">Reading Day</th>
                                              <th scope="col">TC No</th>
                                              <th scope="col">TC Name</th>
                                              <th scope="col">Installations</th>
                                              <th scope="col">TC Consumption</th>
                                              <th scope="col">Installations Consumption</th>
                                              <th scope="col">% Loss</th>
                                              <th scope="col">View</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              auditedTransformers.length === 0 ? <NoDataFoundRow /> :
                                                auditedTransformers.map((transformer, index) => {
                                                  return (
                                                    <tr>
                                                      <td className="ps-4" scope="row">{index + 1}</td>
                                                      <td>{mrCode}</td>
                                                      <td>{transformer.readingDay}</td>
                                                      <td>{transformer.number}</td>
                                                      <td>{transformer.name}</td>
                                                      <td>{transformer.installationCount}</td>
                                                      <td>{transformer?.latestReading?.consumption ?? "N/A"}</td>
                                                      <td>{transformer?.audit?.installationConsumption} Units</td>
                                                      <td> {`${transformer?.currentLoss?.value}%` ?? "N/A"} </td>
                                                      <td>
                                                        <div style={{ cursor: 'pointer' }} onClick={() => history.push({
                                                          pathname: `/tc-audit/${index + 1}`,
                                                          search: '?status=audited',
                                                          state: {
                                                            transformerData: transformer
                                                          }
                                                        })}>
                                                          <img className="img-fluid icon-width-height" src={ViewImage} />
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  )
                                                })
                                            }
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <nav aria-label="Page navigation example" className="pagination-1 pt-3 pb-3">
                                      <ul className="pagination mb-0">
                                        <li className="page-item next-item"><a className="page-link border-0" href="#">Previous</a></li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item previous-item mx-3"><span>Out of 100</span><a className="page-link border-0 active" href="#">Next</a></li>
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                              <div className={`tab-pane fade third-toggle-tab mb-4 ${activeDataTab == 'transformers-unaudited' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="contact-tab">
                                <div className="row top-transformer-tab">
                                  <div className="col-md-4">
                                    <div className="card custom-card-tc-4">
                                      <div className={`cp card-body ${activeTransformerUnauditedButton == 'tc-intagged' ? 'active-card-body' : ''}`} onClick={(e) => unauditedTransformersButtonHandler('tc-intagged')}>
                                        <p className="card-text mb-0 me-auto font-regular-400">TC's Untagged</p>
                                        <hr />
                                        <h4 className="mb-0 font-medium-500">{auditResult.tcUntagged}</h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="card custom-card-tc-5">
                                      <div className={`cp card-body ${activeTransformerUnauditedButton == 'tc-without-fr' ? 'active-card-body' : ''}`} onClick={(e) => unauditedTransformersButtonHandler('tc-without-fr')}>
                                        <p className="card-text mb-0 me-auto font-regular-400">TC's Without FR</p>
                                        <hr />
                                        <h4 className="mb-0 font-medium-500">{auditResult.tcWithoutFR}</h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="card custom-card-tc-6">
                                      <div className={`cp card-body ${activeTransformerUnauditedButton == 'tc-with-remarks' ? 'active-card-body' : ''}`} onClick={(e) => unauditedTransformersButtonHandler('tc-with-remarks')}>
                                        <p className="card-text mb-0 me-auto font-regular-400">TC's With Remarks</p>
                                        <hr />
                                        <h4 className="mb-0 font-medium-500">{auditResult.tcWithRemarks}</h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="main-tc-table list-view-box mt-4">
                                      <div className="search-heading">
                                        <div className="search-space position-relative me-auto">
                                          <input type="text" placeholder="Search by TC Number or Name" className="form-control" />
                                          <img className="img-fluid fa" src={searchIcon} />
                                        </div>
                                        <div className="download-result">
                                          <a href="#" className="download-bt me-3">
                                            <img src={downloadImage} className="me-2" />
                                            <p className="mb-0">Download Result</p>
                                          </a>
                                          <button type="button" className="btn btn-primary filter-btn" onClick={filterHandler}>
                                            <img src={filterIcon} className="" width="18" height="12" />Filter
                                          </button>
                                        </div>
                                      </div>

                                      <div className="table-responsive">
                                        <table className="table mt-0">
                                          <thead>
                                            <tr>
                                              <th className="ps-4" scope="col">#</th>
                                              <th scope="col">MR Code</th>
                                              <th scope="col">Reading Day</th>
                                              <th scope="col">TC No</th>
                                              <th scope="col">TC Name</th>
                                              <th scope="col">Installations</th>
                                              <th scope="col">TC Consumption</th>
                                              <th scope="col">Installations Consumption</th>
                                              <th scope="col" className="pr-20">Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              unauditedTransformers.length === 0 ? <NoDataFoundRow /> :
                                                unauditedTransformers.map((transformer, index) => {
                                                  return (
                                                    <tr>
                                                      <td className="ps-4" scope="row">{index + 1}</td>
                                                      <td>{mrCode}</td>
                                                      <td className="font-regular-400">{transformer.readingDay}</td>
                                                      <td className="font-regular-400">{transformer.number}</td>
                                                      <td className="font-regular-400">{transformer.name}</td>
                                                      <td className="font-regular-400">{transformer.installationCount}</td>
                                                      <td className="font-regular-400">{transformer?.latestReading?.consumption ?? "N/A"}</td>
                                                      <td className="font-regular-400">{transformer?.audit?.installationConsumption} Units</td>
                                                      <td>
                                                        {/* <Link className="audit me-2" to={`/tc-audit/${index + 1}?status=unaudited`}>Audit</Link> */}
                                                        <div style={{ cursor: 'pointer', display: 'inline' }} onClick={() => history.push({
                                                          pathname: `/tc-audit/${index + 1}`,
                                                          search: '?status=unaudited',
                                                          state: {
                                                            transformerData: transformer
                                                          }
                                                        })}>
                                                          <img className="img-fluid icon-width-height" src={ViewImage} />
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  )
                                                })
                                            }
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <nav aria-label="Page navigation example" className="pagination-1 pt-3 pb-3">
                                      <ul className="pagination mb-0">
                                        <li className="page-item next-item"><a className="page-link border-0" href="#">Previous</a></li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item previous-item mx-3"><span>Out of 100</span><a className="page-link border-0 active" href="#">Next</a></li>
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}