import { API } from "aws-amplify"

export const uploadInstallations = () => {
    return API.post('baseURL', `/ae/file-upload/installationStatusReport`)
}

export const uploadTCDetails = () => {
    return API.post('baseURL', `/ae/file-upload/tcDetails`)
}

export const uploadUnbilled = () => {
    return API.post('baseURL', `/ae/file-upload/unbilled`)
}

export const uploadVacant = () => {
    return API.post('baseURL', `/ae/file-upload/vacant`)
}

export const uploadZeroConsumption = () => {
    return API.post('baseURL', `/ae/file-upload/zero-consumption`)
}

export const uploadMNR = () => {
    return API.post('baseURL', `/ae/file-upload/mnr`)
}

export const uploadDoorLock = () => {
    return API.post('baseURL', `/ae/file-upload/door-lock`)
}

export const uploadDCB = () => {
    return API.post('baseURL', `/ae/file-upload/dcb`)
}