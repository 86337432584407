import React, { useEffect, useState } from 'react';
import { Switch, Route, HashRouter, Redirect } from 'react-router-dom';
import Header from './components/Shared/Header';
import Sidebar from './components/Shared/Sidebar';
import TCAudit from './components/EnergyAudit/TCAudit';
import MRList from './components/MRManagement/List';
import Dashboard from './components/Dashboard/Index';
import AddMR from './components/MRManagement/Add';
import FeederList from './components/Feeders/List';
import Billing from './components/Billing/List';
import Disconnections from './components/Disconnections/List';
import './assets/scss/index.scss';
import './assets/scss/style.scss';
import './assets/scss/xyg.scss';
import './assets/css/font-awesome.min.css'
import AddSubstation from './components/Feeders/AddSubstation';
import Login from './components/Auth/Login';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import TCAuditDetail from './components/EnergyAudit/TCAuditDetail';
import TCDetail from './components/EnergyAudit/TCDetail';
import { awsconfig } from './aws-exports'
import Amplify, { Auth } from "aws-amplify";
import Test from './components/Test/Test';
import { AuthContext } from './context/authContext';
import ErrorProvider from './context/errorContext';
import AddFeeder from './components/Feeders/AddFeeder';
import Settings from './components/Test/Settings';

Amplify.configure(awsconfig);

const App = (props) => {

  const sidebarHandler = () => {
    setSideBarPosition(!sideBarMinimized);
  }
  const [sideBarMinimized, setSideBarPosition] = useState(false)
  const [isLoggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    signInSuccessHandler()
  }, [])

  const signInSuccessHandler = () => {
    const refreshToken = localStorage.getItem('refreshToken')
    setLoggedIn((refreshToken !== null) ? true : false);
  }

  let sectionClasses = ['common-scroll-content', sideBarMinimized ? 'common-toggle-content' : ''].join(' ');

  return (
    <AuthContext.Provider value={{ signInSuccessHandler }}>
      <HashRouter>
        <ErrorProvider>
          {
            isLoggedIn ?
              (
                <React.Fragment>
                  <Header sidebarHandler={sidebarHandler} sideBarMinimized={sideBarMinimized} signoutHandler={signInSuccessHandler} />
                  <div className="main-container">
                    <Sidebar sideBarMinimized={sideBarMinimized} />
                    <div className={sectionClasses}>
                      <Switch>
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route exact path="/mr-management" component={MRList} />
                        <Route exact path="/mr-management/add" component={AddMR} />
                        <Route exact path="/mr-management/:id/edit" component={AddMR} />
                        <Route exact path="/feeders" component={FeederList} />
                        <Route exact path="/tc-audit" component={TCAudit} />
                        <Route exact path="/tc-audit/:id" render={props => props.location.state ? <TCAuditDetail /> : <Redirect to="/tc-audit" />}/>
                        <Route exact path="/tc-details" component={TCDetail} />
                        <Route exact path="/add-substation" component={AddSubstation} />
                        <Route exact path="/substations/:id/feeders/add" render={props => props.location.state ? <AddFeeder /> : <Redirect to="/feeders" />} />
                        <Route exact path="/test" component={Test} />
                        <Route exact path="/billing" component={Billing} />
                        <Route exact path="/disconnections" component={Disconnections} />
                        <Route exact path="/settings" component={Settings} />
                        <Route exact path="/" component={Dashboard} />
                        <Redirect from="/login" to="/dashboard" />
                      </Switch>
                    </div>
                  </div>
                </React.Fragment>
              )
              :
              (
                <React.Fragment>
                  <Switch>
                    <Redirect exact from="/" to="/login" />
                    <Route exact path="/login">
                      <Login signInSuccess={signInSuccessHandler} />
                    </Route>
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Redirect from="/dashboard" to="/login" />
                  </Switch>
                </React.Fragment>
              )
          }
        </ErrorProvider>
      </HashRouter>
    </AuthContext.Provider>
  );
}

export default App;
