import { Auth } from 'aws-amplify';


export const awsconfig = {
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'ap-south-1:d6bad0bb-e867-4e19-8b79-c1ce2a3d7a15',

        // REQUIRED - Amazon Cognito Region
        region: 'ap-south-1',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: 'ap-south-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-south-1_v17AGbpmw',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '2n7lb6sq1dus7jii9bl09fpasp',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // // OPTIONAL - Configuration for cookie storage
        // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //   domain: '.yourdomain.com',
        //   // OPTIONAL - Cookie path
        //   path: '/',
        //   // OPTIONAL - Cookie expiration in days
        //   expires: 365,
        //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        //   sameSite: "strict" | "lax",
        //   // OPTIONAL - Cookie secure flag
        //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //   secure: true
        // },
    },
    API: {
        endpoints: [
            {
                name: "baseURL",
                endpoint: "https://t61c4a788f.execute-api.ap-south-1.amazonaws.com/demo/v1",
                region: 'ap-south-1',
                // custom_header: async () => {
                //     return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                // }
            },
            {
                name: "addMR",
                endpoint: "https://smvs8vz3h0.execute-api.ap-south-1.amazonaws.com/dev/admin/hello",
                custom_header: async () => {
                    // return { Authorization: 'token' }
                    // Alternatively, with Cognito User Pools use this:
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            },
            {
                name: "test",
                endpoint: "https://smvs8vz3h0.execute-api.ap-south-1.amazonaws.com/dev/user/hello",
                custom_header: async () => {
                    // return { Authorization: 'token' }
                    // Alternatively, with Cognito User Pools use this:
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            }
        ]
    }
};