import { API } from 'aws-amplify'


export const getBillingCount = () => {
    return API.get('baseURL', '/ae/billing-count?date=february-2021')
}

export const tagTC = (installationId,transformerId) => {
    return API.patch('baseURL', `/ae/installations/tag-tc-rr/${installationId}/${transformerId}`)
}

export const getAllTCs = () => {
    return API.get('baseURL', '/ae/transformer-centres')
}

export const getMyInstallationsData = () => {
    return API.get('baseURL', '/ae/installations')
}

export const getNewInstallationsData = () => {
    return API.get('baseURL', '/ae/new-installations?date=february-2021')
}

export const getUnbilledData = () => {
    return API.get('baseURL', '/ae/billing/unbilled?date=february-2021')
}

export const getVacantData = () => {
    return API.get('baseURL', '/ae/billing/vacant?date=february-2021')
}

export const getZeroConsumptionData = () => {
    return API.get('baseURL', '/ae/billing/zero-consumption?date=february-2021')
}

export const getMNRData = () => {
    return API.get('baseURL', '/ae/billing/mnr?date=february-2021')
}

export const getDoorLockData = () => {
    return API.get('baseURL', '/ae/billing/door-lock?date=february-2021')
}