import React from 'react';
import { Pie } from "react-chartjs-2";

const DisconnectionDetailsChart = () => {

  const disconnectionDetailsChartData = {
    labels: ["Meter Removed", "Gov. Installation", "Pole Disconnection", "Paid"],
    datasets: [
      {
        label: "Rainfall",
        backgroundColor: ["#3B1FED", "#D81B60", "#9C27B0", "#FF9800"],
        hoverBackgroundColor: ["#501800", "#D81B60", "#9C27B0", "#FF9800"],
        data: [65, 59, 80, 81],
      },
    ],
  };

  return <Pie
    data={disconnectionDetailsChartData}
    options={{
      tooltips: {
        enabled: false,
      },
      plugins: {
        labels: false,
        title: {
          display: false,
          text: "Average Rainfall per month",
          fontSize: 10,
        },
        legend: {
          display: true,
          position: "right",
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      showAllTooltips: true,
    }}
  />
}

export default DisconnectionDetailsChart;