import React, { useState } from "react";

const Modal = (props) => {

  let type = props.type;
  const options = type == 'export' ? [
    {
      name: 'PDF',
      value: 'pdf'
    },
    {
      name: 'CSV',
      value: 'csv'
    },
    {
      name: 'Word Doc',
      value: 'doc'
    }
  ] : [
    ...props.data
    // {
    //   name: 'TC 235',
    //   value: '235'
    // },
    // {
    //   name: 'TC 236',
    //   value: '236'
    // },
    // {
    //   name: 'TC 237',
    //   value: '237'
    // }
  ];

  const okButtonHandler = (e) => {
    if (options.length !== 0) {
      props.onTagTC();
      props.onModalClose(e)
      return
    }
    props.onModalClose(e)
  }

  const style = {
    display: props.isModalVisible ? 'block' : 'none'
  };
  return (
    <React.Fragment>
      <div className="modal export-excel-modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={style}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLabel">{props.title}</h6>
              <button onClick={props.onModalClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {
                options.length === 0 ? <div>No TCs found to Tag this installation.</div> :
                  options.map((option, index) => {
                    return (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value={option.transformerId}
                          checked={option.transformerId == props.value}
                          onChange={(e) => props.onChange(e.target.value)}
                        />
                        <label className="form-check-label" for="flexRadioDefault1">TC - {option.number}</label>
                      </div>
                    )
                  })
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={props.onModalClose}>Cancel</button>
              <button type="button" className="btn btn-primary" onClick={okButtonHandler}>OK</button>
            </div>
          </div>
        </div>
      </div>
      {props.isModalVisible && (
        <div className="modal-backdrop fade show"></div>
      )}
    </React.Fragment>
  );
};

export default Modal;
