import React, { Fragment, useContext, useEffect, useState } from 'react';
import transformerImage from '../../assets/img/transfarmer-icon.png';
import plusImage from '../../assets/img/plus.svg';
import ForgotPaasword from '../Auth/ForgotPassword';
import TCDetailComponent from './SubComponents/TCDetail';
import AddTCComponent from './SubComponents/AddTC';
import searchIcon from '../../assets/img/search-tc-icon.svg';
import downIcon from '../../assets/img/other-main-down-arrow.svg';
import upIcon from '../../assets/img/other-main-up-arrow.svg';
import deleteIcon from '../../assets/img/delete-tab-icon.svg';
import { ErrorContext } from '../../context/errorContext';
import { getAllTCs } from '../../services/api/tcService';
import FullScreenLoader from '../Shared/FullScreenLoader';

const TCDetail = () => {

  const componentsObject = {
    'tc_details': TCDetailComponent,
    'tc_add': AddTCComponent
  };

  const errorContext = useContext(ErrorContext)

  const [transformers, setTransformers] = useState([]);
  const [selectedTransformer, setSelectedTransformer] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeTItem, setActiveTItem] = useState(0);
  const [activeFormKey, updateActiveFormKey] = useState('tc_details');
  const [randomNumber, updateRandomNumber] = useState(5);
  const [randomNumberInstallations, updateRandomNumberInstallations] = useState(9);

  useEffect(() => {
    fetchAllTCs()
  }, [])

  const fetchAllTCs = async () => {
    try {
      const response = await getAllTCs();
      setTransformers(response.data)
      setSelectedTransformer(response.data[0])
      setLoading(false)
    }
    catch (err) {
      errorContext.showError(err)
      setLoading(false)
    }
  }

  const setTransformerClickHandler = (index,transformer) => {
    let anotherNumber = Math.floor(Math.random() * 5) + 1;
    updateRandomNumber(anotherNumber);
    anotherNumber = Math.floor(Math.random() * 9) + 1;
    updateRandomNumberInstallations(anotherNumber);
    setActiveTItem(index);
    setSelectedTransformer(transformer)
  }

  const addTCFormHandler = (event) => {
    updateActiveFormKey('tc_add');
  }

  const tcDetailsScreenHandler = event => {
    updateActiveFormKey('tc_details');
  }

  return (
    <Fragment>
      {loading ? <FullScreenLoader />
        : transformers.length === 0 ? <div style={{display:"flex",flex:1,alignItems:'center',justifyContent:'center',height:'85vh'}}>No Transformer Found</div> :
        <div className="my-installation-content tc-audit-content audit-list-box">
          <div className="container pt-0 pl-0">
            <div className="row">
              <div className="col-md-3">
                <div className="tc-audit-no-list">
                  <div className="add-new-tc-no pt-1 pb-1">
                    <card className="collapse-text search-collpase-text">
                      <img className="" src={searchIcon} />
                      <input type="text" placeholder="Search TC" className="form-control" />
                    </card>
                  </div>
                  <div onClick={addTCFormHandler} className="add-new-tc-no pt-3 pb-3 ps-2 pe-2">
                    <card className="collapse-text">
                      <div className="mb-0 tc-audit-no-title ms-1 me-auto">
                        <p className="mb-0 tc-no-text font-regular-400">Add New TC</p>
                      </div>
                      <div className="delete-tc-audit me-3">
                        <a href="#">
                          <img className="img-fluid" src={plusImage} />
                          {/* <i className="fa fa-plus" aria-hidden="true"></i> */}
                        </a>
                      </div>
                    </card>
                  </div>
                  {
                    transformers.map((transformer, index) => {
                      return (
                        <Fragment>
                          <div onClick={() => setTransformerClickHandler(index,transformer)} className="tc-audit-no-collapse pt-3 pb-3 ps-2 pe-2">
                            <card className="collapse-text" data-bs-toggle="collapse" href="#collapseTcAuditNo-1" role="button" aria-expanded="false" aria-controls="collapseExample">
                              <img className="img-fluid" src={transformerImage} />
                              <div className="mb-0 tc-audit-no-title ms-2">
                                <p className="mb-1 tc-no roboto font-medium-500">TC No: {transformer.number}</p>
                                <p className="mb-0 tc-no-text roboto font-light-300">{transformer.name}</p>
                              </div>
                              <div className="delete-tc-audit">
                                {index == activeTItem && <a href="#" className="trash-tc"><img className="img-fluid fa" src={deleteIcon} /></a>}
                                <img className="img-fluid fa" src={`${index == activeTItem ? upIcon : downIcon}`} />
                                {/* <i className={`fa fa-angle-${index == activeTItem ? 'up' : 'down'}`} aria-hidden="true"></i> */}
                              </div>
                            </card>
                          </div>
                          <div className={`collapse collapse-detail ${index == activeTItem ? 'show' : ''}`} id="collapseTcAuditNo-1">
                            <div className="card card-body border-0 pe-2 ps-2 pt-2 pb-2">
                              <div className="tc-installation-loss text-center mt-3 mb-2">
                                <span className="me-2"><label class="number">{transformer.installationCount}</label> Installation</span>
                                <span><label class="number">{'currentLoss' in transformer ? transformer.currentLoss.value : '-'}%</label> Loss</span>
                              </div>
                              <ul className="mb-0 p-3">
                                {/* <li><b className="roboto font-bold">TC Code:</b> 3456</li> */}
                                <li><b className="roboto font-bold">Tims Code:</b> {transformer.timsCode}</li>
                                <li><b className="roboto font-bold">DTLMS CODE:</b> {transformer.dtmsCode ? transformer.dtmsCode : '-'}</li>
                                <li><b className="roboto font-bold">DTR Code:</b> {transformer.dtrCode ? transformer.dtrCode : '-'}</li>
                              </ul>
                            </div>
                          </div>
                        </Fragment>
                      )
                    })
                  }
                </div>
              </div>
              <div className="col-md-9">
                {activeFormKey == 'tc_details' && <TCDetailComponent
                  tcDetails={selectedTransformer}
                  randomNumber={randomNumber}
                  randomNumberForInstallations={randomNumberInstallations}
                />
                }
                {activeFormKey == 'tc_add' && <AddTCComponent
                  onCloseButton={tcDetailsScreenHandler} />
                }
              </div>
            </div>
          </div>
        </div>}
    </Fragment>
  );
};

export default TCDetail;