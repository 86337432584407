import React, { useContext, useEffect, useState } from 'react';
import filterIcon from '../../../assets/img/filter.png';
import excelIcon from '../../../assets/img/export-dark-icon.png';
import Filter from '../../Shared/Filter';
import searchIcon from '../../../assets/img/search-tc-icon.svg';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorContext } from '../../../context/errorContext';
import { getMNRData } from '../../../services/api/billingService';
import NoDataFoundRow from '../../Shared/NoDataFoundRow';
import FullScreenLoader from '../../Shared/FullScreenLoader';
import { mrCode } from '../../../assets/constants';
import Ellipsis from '../Ellipsis';

const Table = () => {

  const errorContext = useContext(ErrorContext)

  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState(new Date());
  const [filterVisible, setFilterVisible] = useState(false);
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    fetchMNRData()
  }, [])


  const fetchMNRData = async () => {
    try {
      const response = await getMNRData()
      setData(response.data)
      setLoading(false)
    }
    catch (err) {
      errorContext.showError(err)
      setLoading(false)
    }
  }

  const filterHandler = (event) => {
    event.preventDefault();
    setFilterVisible(!filterVisible);
  }

  const applyFilters = (filters) => {
    console.log(filters);
  }

  return (
    <React.Fragment>
      <Filter enabledFilters={{ durations: true, tariffs: true, tags: false, remarks: true }} isFilterVisible={filterVisible} onFilterClose={filterHandler} onFiltersApply={applyFilters} />
      {loading ? <FullScreenLoader />
        :
        <div className="col-md-9">
          <div className="row mt-35">
            <div className="col-md-12">
              <div className="comman-head">
                <div className="installation-title">
                  <h1>MNR</h1>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="main-tc-name-form mb-3">
                <div className="search-form">
                  <form>
                    <div className="forms-input">
                      <div className="row">
                        <div className="col-12 col-md-5 col-lg-5">
                          <input className="form-control" type="text" value={mrCode} disabled placeholder="Enter MR Code" />
                        </div>
                        <div className="col-12 col-md-4 col-lg-5 reading-day-input">
                          <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                        </div>
                        <div className="col-12 col-md-3 col-lg-2">
                          <button type="submit" style={{ marginTop: '0px' }} className="btn btn-primary">Search</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>


                <div className="row">
                  <div className="col-md-12">
                    <div className="list-view-box">
                      <div className="search-heading">
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <div className="search-space">
                              <input type="text" placeholder="Search" className="form-control search" />
                            </div>
                          </div>
                          <div class="col-12 col-md-6 text-right">
                            <div className="export-filter">
                              <a href="#" className="export-btn">
                                <img className="fa img-fluid" src={excelIcon} />&nbsp; <span>Export to Excel</span>
                              </a>
                              <button type="button" className="btn btn-primary filter-btn">
                                <img src={filterIcon} className="" width="18" height="12" />Filter
                            </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="pl-15" scope="col">#</th>
                              <th scope="col">MR Code</th>
                              <th scope="col">Reading Day</th>
                              <th scope="col">Account ID</th>
                              <th scope="col">RR Number</th>
                              <th scope="col">Tariff</th>
                              <th scope="col">Consumer Name</th>
                              <th scope="col">Sanction Load</th>
                              <th scope="col">Duration</th>
                              <th scope="col">Remark</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.length === 0 ? <NoDataFoundRow />
                              :
                              data.map((item, index) => (
                                <tr key={index}>
                                  <td className="pl-15" scope="row">{index + 1}</td>
                                  <td>{mrCode}</td>
                                  <td style={{ textAlign: 'center' }}>{item.readingDay}</td>
                                  <td>{item.accountNumber}</td>
                                  <td>{item.rrNumber}</td>
                                  <td>{item.tariff}</td>
                                  <Ellipsis>{item.consumerName}</Ellipsis>
                                  <td>{item.sanctionedLoad.kW} KW</td>
                                  <td>{item.from}</td>
                                  <td>Building Demolish</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <nav className="float-end" aria-label="Page navigation example">
                <ul className="pagination mb-0">
                  <li className="page-item next-item"><a className="page-link border-0" href="#">Previous</a></li>
                  <li className="page-item"><a className="page-link" href="#">1</a></li>
                  <li className="page-item previous-item"><span>Out of 100</span><a className="page-link border-0 active" href="#">Next</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>}
    </React.Fragment>
  );
}

export default Table;