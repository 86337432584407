import React, { createContext, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { AuthContext } from './authContext';
import { useHistory } from 'react-router';

export const ErrorContext = createContext()


const ErrorProvider = ({children}) => {

    const authContext = useContext(AuthContext)
    const history = useHistory()

    const modalOpen = (err) => {
        Auth.currentSession()
            .then(data => alert(`Something went wrong.:${JSON.stringify(err?.response)??err},${err?.response?.status ?? ""} `))
            .catch(err => {
                localStorage.clear();
                // alert(window.history.length)
                authContext.signInSuccessHandler()
                history.push("/")
                alert("Session Expired please login again.")
            });
    }

    const modalClose = (err) => {

    }
    return ( 
        <ErrorContext.Provider value={{
            showError:modalOpen,
            closeError:modalClose
        }}>
            {children}
        </ErrorContext.Provider>
     );
}
 
export default ErrorProvider;