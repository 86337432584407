import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import closeIcon from '../../assets/img/close-icon.png';
import closeIcon1 from '../../assets/img/close-icon@2x.png';
import searchIcon from '../../assets/img/search-tc-icon.svg';
import { ErrorContext } from '../../context/errorContext';
import { addFeeder } from '../../services/api/feederService';

const AddFeeder = (props) => {

    const location = useLocation()
    const history = useHistory();
    const errorContext = useContext(ErrorContext)


    const { name, powerStationId, stationCode } = location?.state?.powerStationData

    const [stationFields] = useState({ name, voltage: "", stationCode })
    const [formFields, setFormFields] = useState({ name: '', mdm_code: '', number: '' });
    const [isFormValid, setFormValid] = useState(false);
    const [loading, setLoading] = useState(false)
    const [feeders, setFeeders] = useState([]);


    useEffect(() => {
        /** Debouncing */
        const timer = setTimeout(() => {
            let checkForm = true;
            for (const [key, value] of Object.entries(formFields)) {
                if (validateField(key) !== '') {
                    checkForm = false;
                }
            }
            setFormValid(checkForm);
        }, 200);
        return () => {
            clearTimeout(timer);
        }
    }, [formFields])

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const validateField = (name) => {
        let error = '';
        /** Generic test */
        error = formFields[name].length == 0 ? 'Please enter valid value' : '';
        return error;
    }

    const deleteFeeder = (index) => {
        const feederList = [...feeders]
        feederList.splice(index, 1)
        setFeeders(feederList)
    }


    const saveHandler = async (event) => {
        event.preventDefault();
        if (!isFormValid) return;
        setFeeders([...feeders, formFields])
        setFormFields({ name: "", mdm_code: "", number: "" })
    }

    const submitHandler = async () => {
        try {
            setLoading(true)
            await Promise.all(feeders.map(feeder => addFeeder({
                name: feeder.name,
                number: feeder.number,
                mdmCode: feeder.mdm_code,
                powerStation: { powerStationId: powerStationId }
            })))
            setLoading(false)
            history.goBack()
        }
        catch (err) {
            errorContext.showError(err);
            setLoading(false)
        }

    }

    return (
        <React.Fragment>
            <div className="add-substation">
                <div className="container pt-3">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="code-section">
                                <span>Feeder Management&nbsp; |</span>
                                <span className="add-new-substation-text"><a href="#">&nbsp; {stationFields.name}</a></span>
                            </div>
                            <hr className="horizontal-line" />
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="substation-title">
                                <h1 className="mb-0">Add New Feeder</h1>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="close-mr">
                                <Link onClick={() => history.goBack()} className="btn btn-primary bg-transparent"><img src={closeIcon} />Close
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div className="main-substation-form p-3 mb-3">
                                <form>
                                    <div className="forms-input pb-10">
                                        <div className="form-substation-shadow">
                                            <div className="row">
                                                <div className="col-md-3 search-feeder">
                                                    <label className="form-label font-regular-400">Station Code*</label>
                                                    <input
                                                        className="form-control"
                                                        value={stationFields.stationCode}
                                                        type="text" placeholder="Station Code (Optional)"
                                                        disabled />
                                                    <img className="img-fluid fa" src={searchIcon} />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="form-label font-regular-400">Voltage Class</label>
                                                    <select className="form-select" aria-label="Default select example" disabled>
                                                        <option>Voltage Class</option>
                                                        <option value="1">66KV</option>
                                                        <option value="2">110KV</option>
                                                        <option value="3">220KV</option>
                                                        <option value="3">400KV</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="form-label font-regular-400">Station Name</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={stationFields.name}
                                                        placeholder="Station Name" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {/* <hr className="divider" /> */}
                                <div className="main-feeder-div">
                                    <div className="feeder-title">
                                        <h3 className="mb-0">Add Feeder</h3>
                                    </div>
                                    <form className="feeder-form">
                                        <div className="forms-input">
                                            <div className="row">
                                                <div className="col-md-3 search-feeder">
                                                    <label className="form-label">Feeder MDM Code</label>
                                                    <input onChange={handleChange} name="mdm_code" value={formFields['mdm_code']} className="form-control" type="text" placeholder="Search or Add by MDM Code" />
                                                    <img className="img-fluid fa" src={searchIcon} />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="form-label">Feeder Number</label>
                                                    <input onChange={handleChange} name="number" value={formFields['number']} className="form-control" type="text" placeholder="Feeder Number" />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="form-label">Feeder Name</label>
                                                    <input onChange={handleChange} name="name" value={formFields['name']} className="form-control" type="text" placeholder="Feeder Name" />
                                                </div>
                                                <div className="col-md-3 mr-save-btn">
                                                    <button onClick={saveHandler} type="submit" className={`btn btn-primary ${isFormValid ? 'custom-active' : ''}`}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {
                                    feeders.map((feeder, index) => {
                                        return (
                                            <div className="feeder-section mt-3" key={index}>
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-10 col-10">
                                                        <div className="feeder-name">
                                                            <p className="mb-0">Feeder MDM Code : <b>{feeder.mdm_code}</b></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-10 col-10">
                                                        <div className="feeder-number">
                                                            <p className="mb-0">Feeder Number : <b>{feeder.number}</b></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-3 col-sm-10 col-10">
                                                        <div className="feeder-name">
                                                            <p className="mb-0">Feeder Name : <b>{feeder.name}</b></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-2 text-end">
                                                        <div className="delete-btn">
                                                            <img
                                                                class="img-fluid icon-width-height-24"
                                                                style={{ cursor: "pointer" }}
                                                                src={closeIcon1}
                                                                onClick={() => deleteFeeder(index)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                <div className="row">
                                    <div className="col-md-12 mr-submit-btn">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            disabled={loading}
                                            onClick={submitHandler}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AddFeeder;
