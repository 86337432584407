import React, { useState } from "react";
import bigLogo from "../../assets/img/big-menu-logo.png";
import smallLogo from "../../assets/img/mr-logo_54.png";
import profile from "../../assets/img/user-pic.png";
import menuIcon from "../../assets/img/menu-icon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Auth } from 'aws-amplify';
import { fetchAEDetails } from "../../services/helpers/meta";


const Header = (props) => {
  const [startDate, setStartDate] = useState("");

  const handleSignout = async () => {
    try {
      const confirmation = window.confirm("Are you sure you want logout?");
      if (confirmation) {
        await Auth.signOut();
        localStorage.clear();
        props.signoutHandler()
      }
    }
    catch (error) {
      console.log('error signing out: ', error);
    }

  }

  return (
    <React.Fragment>
      {/* <header class="site-header"> */}
        <nav className="navbar navbar-expand-lg navbar-light main-navbar sticky-top bg-gray">
          <div className="container-fluid">
            <div
              className="custom-collapse navbar-collapse"
              id="navbarTogglerDemo01"
            >
              <a className="navbar-brand desktop-logo d-lg-block" href="#">
                <img
                  src={bigLogo}
                  className={`big-logo ${props.sideBarMinimized ? "import" : ""}`}
                />
                <img
                  src={smallLogo}
                  className={`small-logo ${
                    props.sideBarMinimized ? "important" : ""
                  }`}
                />
              </a>
              <button
                type="button"
                onClick={props.sidebarHandler}
                className="slide-left me-auto"
              >
                <img className="img-fluid" src={menuIcon} />
              </button>
              <ul className="navbar-nav me-4">
                <li className="nav-item roboto font-regular-400">
                  <span className="audit-text roboto font-medium-500">Audit Month</span>
                  <DatePicker
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    showThreeColumnMonthYearPicker
                    placeholderText="Select"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </li>
                <hr className="vertical-line" />
                <li className="nav-item dropdown user-profile-icon">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={handleSignout}
                  >
                    {/* <img
                      src={profile}
                      className="avatar img-fluid rounded-circle me-3"
                      width="40"
                      alt="Charles Hall"
                    /> */}
                    <span>
                      <b className="roboto font-medium-500">{fetchAEDetails('name')}</b>
                      <p className="mb-0 roboto font-regular-400">AE- Whitefild</p>
                    </span>
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className="dropdown-item" href="">
                        Profile
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="">
                        Settings
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="">
                        Help
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="">
                        Sign out
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      {/* </header> */}
    </React.Fragment>
  );
};

export default Header;
