import { API } from "aws-amplify"

export const runAudit = () => {
    return API.post('baseURL', `/ae/audit`)
}

export const getAuditResult = (executionType='') => {
    const url = executionType ? `/ae/audit?executionType=${executionType}` : '/ae/audit'
    return API.get('baseURL', url)
}
