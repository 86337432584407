import React, { useState } from 'react'
import filterIcon from '../../assets/img/filter.png';
import Filter from '../Shared/Filter';
import Modal from '../Shared/Modal';
import searchIcon from '../../assets/img/search-tc-icon.svg';
import excelIcon from '../../assets/img/export-dark-icon.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const List = () => {
  const displayStyle = {
    display: "-webkit-inline-box",
};
  const [startDate, setStartDate] = useState('');
  const disconnectionsArray = [
    {
      mrCode: "123457",
      readingDay: 1,
      accountId: 1234567890,
      rrNumber: '4EEH1234',
      tariff: 12,
      cusumerName: "Ganesh Kharatmal",
      sanctionLoad: '2 KW',
      duration: '2 Months',
      arrears: '5000',
      remarks: "Building Demolish"
    },
    {
      mrCode: "123456",
      readingDay: 1,
      accountId: 1234567890,
      rrNumber: '4EEH1234',
      tariff: 12,
      cusumerName: "Ganesh Kharatmal",
      sanctionLoad: '2 KW',
      duration: '2 Months',
      arrears: '5000',
      remarks: "Building Demolish"
    },
    {
      mrCode: "123456",
      readingDay: 1,
      accountId: 1234567890,
      rrNumber: '4EEH1234',
      tariff: 12,
      cusumerName: "Ganesh Kharatmal",
      sanctionLoad: '2 KW',
      duration: '2 Months',
      arrears: '5000',
      remarks: "Building Demolish"
    }
  ];
  const [disconnections, setDisconnections] = useState(disconnectionsArray);
  const [filterVisible, setFilterVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const filterHandler = () => {
    setFilterVisible(!filterVisible);
  }

  const modalHandler = () => {
    setModalVisible(!modalVisible);
  }

  const applyFilters = (filters) => {
    console.log(filters);
  }

  return (
    <React.Fragment>
      <Filter enabledFilters={ { durations: true, tariffs: true, tags: false, remarks: true } } isFilterVisible={filterVisible} onFilterClose={filterHandler} onFiltersApply={applyFilters} />
      <Modal type="export" title="Select file format" isModalVisible={modalVisible} onModalClose={modalHandler}/>
      <div className="disconnection-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="comman-head">
                <div className="management-title">
                  <h1>Disconnections</h1>
                </div>
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="search-form">
                <form>
                  <div className="forms-input">
                    <div className="row">
                      <div className="col-md-4 col-lg-4 col-xl-3">
                        <input className="form-control" type="text" placeholder="Enter MR Code" />
                      </div>
                      <div className="col-md-4 col-lg-4 col-xl-3 reading-day-input">
                      <DatePicker  selected={startDate} placeholderText="Enter Reading date" onChange={date => setStartDate(date)} />
                      </div>
                      <div className="col-md-3 col-lg-3">
                        <button type="submit" className="btn btn-primary">Search</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="list-view-box">
                <div className="search-heading">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div className="search-space">
                        <input type="text" placeholder="Search" className="form-control search"/>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 text-right">
                      <div className="export-filter">
                        <a onClick={modalHandler}  className="export-btn" style={displayStyle}>
                        <img className="fa img-fluid" src={excelIcon} />&nbsp; <span>Export to Excel</span>
                        </a>
                        <button onClick={filterHandler} type="button" className="btn btn-primary filter-btn">
                        <img src={filterIcon} className="" width="18" height="12"/>Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table className="table">
                    <thead className="text-center">
                      <tr>
                        <th className="pl-15" scope="col">#</th>
                        <th scope="col">MR Code</th>
                        <th scope="col">Reading Day</th>
                        <th scope="col">Account ID</th>
                        <th scope="col">RR Number</th>
                        <th scope="col">Tariff</th>
                        <th scope="col">Consumer Name</th>
                        <th scope="col">Sanction Load</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Arrears</th>
                        <th scope="col">Remark</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {
                        disconnections.map((disconnection, index) => {
                          return (
                            <tr key={index}>
                              <td className="pl-15" scope="row">{ index + 1 }</td>
                              <td>{ disconnection.mrCode }</td>
                              <td>{ disconnection.readingDay }</td>
                              <td>{ disconnection.accountId }</td>
                              <td>{ disconnection.rrNumber }</td>
                              <td>{ disconnection.tariff }</td>
                              <td>{ disconnection.cusumerName }</td>
                              <td>{ disconnection.sanctionLoad }</td>
                              <td>{ disconnection.duration }</td>
                              <td>{ disconnection.arrears }</td>
                              <td>{ disconnection.remarks }</td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <nav aria-label="Page navigation example">
                <ul className="pagination mb-0">
                  <li className="page-item next-item"><a className="page-link border-0" href="#">Previous</a></li>
                  <li className="page-item"><a class="page-link" href="#">1</a></li>
                  <li className="page-item previous-item"><span>Out of 100</span><a className="page-link border-0 active" href="#">Next</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default List;