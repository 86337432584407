import { API } from "aws-amplify"

export const getAllFeeders = (date) => {
    return API.get('baseURL', `/ae/feeders`)
}

export const getAllPowerStations = () => {
    return API.get('baseURL', '/ae/power-stations')
}

export const addPowerStation = (body) => {
    return API.post('baseURL', '/ae/power-stations',{
        body
    })
}

export const addFeeder = (body) => {
    return API.post('baseURL', '/ae/feeders', {
        body
    })
}