import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import upload from '../../assets/img/upload-dark-icon.png';
import AddIcon from '../../assets/img/add-icon@2x.png';
import EditIcon from '../../assets/img/edit-selected-icon@2x.png';
import downIcon from '../../assets/img/other-main-down-arrow.svg';
import upIcon from '../../assets/img/other-main-up-arrow.svg';
import searchIcon from '../../assets/img/search-tc-icon.svg';
import { getAllFeeders, getAllPowerStations } from '../../services/api/feederService';
import { Auth } from 'aws-amplify';
import { dateInDDMMYYYY } from '../../services/helpers/date';
import { fetchSectionAndDivisionCode } from '../../services/helpers/meta'
import { ErrorContext } from '../../context/errorContext';
import NoDataFoundRow from '../Shared/NoDataFoundRow'
import FullScreenLoader from '../Shared/FullScreenLoader';

export default function Listing() {

  const [feederList, setFeederArray] = useState([]);
  const [powerStationList, setPowerStationList] = useState([])
  const [activeItem, setActiveItem] = useState(-1)
  const [loading, setLoading] = useState(true)

  const errorContext = useContext(ErrorContext)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {

      const response = await Promise.all([getAllPowerStations(), getAllFeeders()])
      setPowerStationList(response[0].data)
      setFeederArray(response[1].data)
      setLoading(false)
    }
    catch (err) {
      errorContext.showError(err);
      setLoading(false)
    }
  }

  let history = useHistory();

  var option = [
    { value: 'one', label: 'One' },
    { value: 'two', label: 'Two' },
    { value: 'three', label: 'Three' },
    { value: 'four', label: 'Four' }
  ];
  function logChange(val) {
    console.log("Selected: " + val);
  }

  const accordianHandler = (event) => {
    event.preventDefault();
    if (event.target.type === 'button') {
      history.push(event.target.dataset.url);
    }
    var key = event.currentTarget.getAttribute('data-key');
    if (activeItem == key) {
      setActiveItem(-1);
    } else {
      setActiveItem(key);
    }
  }

  const getFeedersList = (powerStationId) => {
    return (
      feederList.filter(feeder => feeder.powerStation.powerStationId === powerStationId)
    )
  }

  const style1 = {
    width: '20%',
    marginRight: '13px',
    marginTop: '7px'
  };
  const style2 = {
    cursor: 'pointer'
  };
  const style3 = {
    width: '100%',
    opacity: '0'
  };
  const style4 = {
    borderBottom: '1px solid #dddddd'
  };


  return (
    <React.Fragment>
      {loading ? <FullScreenLoader /> :
        <div className="feeder-management-content">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <h1 className="mb-0">Feeder Management</h1>
              </div>

              <div className="col-12 col-md-6 text-right">
                <Link to="/add-substation" className="btn btn-primary add-new-btn">
                  <img className="icon-width-height-24" src={AddIcon} />   Add New Station
              </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 ">
                <hr className="horizontal-line" />
                <div className="code-section">
                  <span>Section Code: <span className="font-medium">{fetchSectionAndDivisionCode('sectionCode')}</span></span>
                  <hr className="vertical-line mx-4" />
                  <span>Sub Division Code: <span className="font-medium">{fetchSectionAndDivisionCode('subDivisionCode')}</span></span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="feeder-management-list list-view-box mt-2" style={{paddingTop:25}}>
                  {/* <div className="search-heading"> */}
                    {/* <div className="row">
                      <div className="col-12 col-md-8 col-lg-6 col-xl-3">
                        <div className="search-space position-relative me-auto">
                          <input type="text" placeholder="Search by Feeder Name or MDM Code" className="form-control" />
                        </div>
                      </div>
                    </div> */}
                  {/* </div> */}
                  {/* <div className="substation-list"> */}
                  {
                    powerStationList.map(function (powerStation, index) {
                      return (
                        <React.Fragment key={index}>
                          <div className="substation-list">
                            <div className="substation-collapse">
                              <a data-key={index} onClick={accordianHandler} className="collapse-text p-3"
                                data-bs-toggle="collapse" href={`#collapseSubstation-${index}`} role="button" aria-expanded={index == activeItem ? 'true' : 'false'}
                                aria-controls="collapseExample">
                                <p className="mb-0  sub-station-title first-sub-title font-medium-500">Station Name: {powerStation.name}</p>
                                <p className="sub-station-title mb-0 second-sub-title font-medium-500">Station Code: {powerStation.stationCode}</p>
                                <div className="add-feeder me-5">
                                  <button
                                    // data-url={`/substations/${powerStation.powerStationId}/feeders/add`}
                                    type="button"
                                    onClick={() => history.push({pathname:`/substations/${powerStation.powerStationId}/feeders/add`,state:{
                                      powerStationData:powerStation
                                    }})}
                                    className="btn btn-primary">
                                    <img className="img-fluid icon-width-height-24" src={AddIcon} />
                                  Add Feeder
                                </button>
                                </div>
                                <img className="img-fluid fa fa-angle-down" src={downIcon} />
                                <img className="img-fluid fa fa-angle-up" src={upIcon} />
                              </a>
                            </div>
                            <div className={`collapse collapse-detail ${index == activeItem ? 'show' : ''}`} id={`collapseSubstation-${index}`}>
                              <div className="card card-body border-0 pe-0 ps-0 pt-0 pb-0">
                                <table className="table mb-0 table-responsive">
                                  <thead>
                                    <tr>
                                      <th className="ps-4" scope="col" style={{ borderBottom: '1px solid #dddddd' }}>#</th>
                                      <th scope="col" style={{ borderBottom: '1px solid #dddddd' }}>Feeder Number</th>
                                      <th scope="col" style={{ borderBottom: '1px solid #dddddd' }}>Feeder Name</th>
                                      <th scope="col" style={{ borderBottom: '1px solid #dddddd' }}>Feeder MDM Code</th>
                                      <th scope="col" style={{ borderBottom: '1px solid #dddddd' }}>Added Date</th>
                                      <th scope="col" style={{ borderBottom: '1px solid #dddddd' }} className="text-right pr-20">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      getFeedersList(powerStation.powerStationId).length === 0 ?
                                        <NoDataFoundRow colSpan="6" message="No Feeders found!" />
                                        :
                                        getFeedersList(powerStation.powerStationId).map((feeder, index) => <tr>
                                          <td className="pl-15" scope="row">{index+1}</td>
                                          <td>{feeder.number}</td>
                                          <td>{feeder?.name ?? "N/A"}</td>
                                          <td>{feeder.mdmCode}</td>
                                          <td>{dateInDDMMYYYY(feeder.createdAt)}</td>
                                          <td className="action text-right">
                                            <a href="#" className="me-3">
                                              {/* <i className="fa fa-pencil" aria-hidden="true"></i> */}
                                              <img className="mr-0 icon-width-height" src={EditIcon} />
                                            </a>
                                          </td>
                                        </tr>)
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })
                  }
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <nav aria-label="Page navigation example">
                  <ul className="pagination mb-0">
                    <li className="page-item next-item"><a className="page-link border-0" href="#">Previous</a></li>
                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                    <li className="page-item previous-item mx-3"><span>Out of 100</span><a className="page-link border-0 active" href="#">Next</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}
