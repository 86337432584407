import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import close from '../../assets/img/close-icon.png'

const Add = (props) => {

  const [formFields, setFormFields] = useState({name: '', code: '', phone: ''});
  const [isFormValid, setFormValid] = useState(false);
  const [id, setId] = useState('');

  useEffect(() => {
    const id = props.match.params.id;
    if (typeof id !== 'undefined') {
      fetchMRDetails(id);
      setId(id);
    }
  }, []);

  const fetchMRDetails = async (id) => {
    const response = await fetch("https://mr-management-e5071-default-rtdb.firebaseio.com/mrs/" + id + ".json")
    const data = await response.json();
    setFormFields({
      ...data
    });
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    if (!isFormValid) return;
    let body = {};
    let method = "POST";
    let url = "https://mr-management-e5071-default-rtdb.firebaseio.com/mrs.json";
    if (id === '') {
      body = {
        ...formFields,
        date_added: new Date().toLocaleString().split(',')[0]
      };
    } else {
      url = "https://mr-management-e5071-default-rtdb.firebaseio.com/mrs/" + id + ".json";
      method = "PUT";
      body = {
        ...formFields
      };
    }
    const response = await fetch(url, {
      method: method,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = await response.json();
    props.history.push('/mr-management');
  }

  useEffect(() => {
    /** Debouncing */
    const timer = setTimeout(() => {
      let checkForm = true;
      for (const [key, value] of Object.entries(formFields)) {
        if (validateField(key) !== '') {
          checkForm = false;
        }
      }
      console.log("Check form", checkForm);
      setFormValid(checkForm);
    }, 200);
    return () => {
      clearTimeout(timer);
    }
  }, [formFields])

  const validateField = (name) => {
    let error = '';
    /** Generic test */
    error = formFields[name].length == 0 ? 'Please enter valid value' : '';
    if (name === 'phone') {
      error = formFields[name].length != 10 || isNaN(formFields[name]) ? 'Phone Number Error' : '';
    }
    return error;
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  return (
    <React.Fragment>
      <div className="add-mr-content">
        <div className="container pt-3">
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="code-section">
                <span>MR Management&nbsp; |</span> 
                <span className="add-new-mr-text"><a href="#">&nbsp; Add New MR</a></span>
              </div>
              <hr className="horizontal-line" />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="management-title">
                <h1 className="mb-0">Add New MR</h1>
              </div>
            </div>
            <div className="col-md-6">
              <div className="close-mr">
                <Link to="/mr-management" className="btn btn-primary bg-transparent">
                  <img src={close} /><p className="mb-0">Close</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="main-management-form p-3 mb-3">
                <form onSubmit={submitHandler}>
                  <div className="forms-input">
                    <div className="inner-add-part">
                    <div className="row">
                      <div className="col-md-3">
                        <label className="form-label font-regular-400">MR Name</label>
                        <input name="name" onChange={handleChange} value={formFields['name']} className="form-control" type="text" placeholder="MR Name" />
                      </div>
                      <div className="col-md-3">
                        <label className="form-label font-regular-400">MR Code</label>
                        <input name="code" value={formFields['code']} onChange={handleChange} className="form-control" type="text" placeholder="MR Code" />
                      </div>
                      <div className="col-md-3">
                        <label className="form-label font-regular-400">Phone Number</label>
                        <input name="phone" onChange={handleChange} value={formFields['phone']} className="form-control" type="text" placeholder="Phone Number" />
                      </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12 mt-1 text-end mr-submit-btn">
                        <button type="submit" onClick={submitHandler} className={`btn btn-primary ${isFormValid ? '' : 'btn-grey'}`}>Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Add;