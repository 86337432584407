import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BillingDetailsChart from "./BillingDetailsChart";
import CollectionDetailsChart from "./CollectionDetailsChart";
import DisconnectionDetailsChart from "./DisconnectionDetailsChart";
import DTCEnergyAuditChart from "./DTCEnergyAuditChart";

const Index = (props) => {

  const [startDate, setStartDate] = useState("");

  const energyAuditChartData = {
    labels: ["wk1"],
    datasets: [
      {
        label: "Total Tc's",
        backgroundColor: "rgba(59, 31, 237, 1)",
        borderColor: "rgba(156, 39, 176, 1)",
        borderWidth: 0,
        data: [205],
      },
      {
        label: "TC Audited",
        backgroundColor: "rgba(156, 39, 176, 1)",
        borderColor: "rgba(59, 31, 237, 1)",
        borderWidth: 0,
        data: [182],
      },
      {
        label: "TC Unaudited",
        backgroundColor: "rgba(216, 27, 96, 1)",
        borderColor: "rgba(59, 31, 237, 1)",
        borderWidth: 0,
        data: [35],
      }
    ],
  };

  return (
    <React.Fragment>
      <div className="dashboard-content">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="card top-tabs">
                <div className="card-body">
                  <p className="card-subtitle mb-2 roboto">Demand</p>
                  <h5 className="card-title mb-0 roboto">&#8377; 50000</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card top-tabs">
                <div className="card-body">
                  <p className="card-subtitle mb-2 roboto">Collection</p>
                  <h5 className="card-title mb-0 roboto">&#8377; 45000</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card top-tabs">
                <div className="card-body">
                  <p className="card-subtitle mb-2 roboto">Collection - Percentage</p>
                  <h5 className="card-title mb-0 roboto">10%</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 dashboard-charts">
            <div className="col-md-6 mb-4 card-group">
              <div className="card">
                <div className="card-body charts">
                  <div className="top-heading">
                    <h5 className="card-subtitle roboto">Collection Details</h5>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showYearPicker
                      dateFormat="yyyy"
                      placeholderText="2021"
                    />
                  </div>
                  <div className="contain">
                    <CollectionDetailsChart />
                  </div>
                  <ul className="mt-3 year-list">
                    <li className="year-active roboto font-medium-500">Jan</li>
                    <li className="roboto font-medium-500">Feb</li>
                    <li className="roboto font-medium-500">Mar</li>
                    <li className="roboto font-medium-500">Apr</li>
                    <li className="roboto font-medium-500">May</li>
                    <li className="roboto font-medium-500">Jun</li>
                    <li className="roboto font-medium-500">Jul</li>
                    <li className="roboto font-medium-500">Aug</li>
                    <li className="roboto font-medium-500">Sep</li>
                    <li className="roboto font-medium-500">Oct</li>
                    <li className="roboto font-medium-500">Nov</li>
                    <li className="roboto font-medium-500">Dec</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4 card-group">
              <div className="card">
                <div className="card-body charts">
                  <div className="top-heading">
                    <h5 className="card-subtitle roboto">Billing Details</h5>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showYearPicker
                      dateFormat="yyyy"
                      placeholderText="2021"
                    />
                  </div>
                  <div className="contain">
                    <BillingDetailsChart />
                  </div>
                  <ul className="mt-3 year-list">
                    <li className="year-active roboto font-medium-500">Jan</li>
                    <li className="roboto font-medium-500">Feb</li>
                    <li className="roboto font-medium-500">Mar</li>
                    <li className="roboto font-medium-500">Apr</li>
                    <li className="roboto font-medium-500">May</li>
                    <li className="roboto font-medium-500">Jun</li>
                    <li className="roboto font-medium-500">Jul</li>
                    <li className="roboto font-medium-500">Aug</li>
                    <li className="roboto font-medium-500">Sep</li>
                    <li className="roboto font-medium-500">Oct</li>
                    <li className="roboto font-medium-500">Nov</li>
                    <li className="roboto font-medium-500">Dec</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 card-group">
              <div className="card">
                <div className="card-body charts">
                  <div className="top-heading">
                    <h5 className="card-subtitle roboto">Disconnection Details</h5>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showYearPicker
                      dateFormat="yyyy"
                      placeholderText="2021"
                    />
                  </div>
                  <div className="contain">
                    <DisconnectionDetailsChart />
                  </div>
                  <ul className="mt-3 year-list">
                    <li className="year-active roboto font-medium-500">Jan</li>
                    <li className="roboto font-medium-500">Feb</li>
                    <li className="roboto font-medium-500">Mar</li>
                    <li className="roboto font-medium-500">Apr</li>
                    <li className="roboto font-medium-500">May</li>
                    <li className="roboto font-medium-500">Jun</li>
                    <li className="roboto font-medium-500">Jul</li>
                    <li className="roboto font-medium-500">Aug</li>
                    <li className="roboto font-medium-500">Sep</li>
                    <li className="roboto font-medium-500">Oct</li>
                    <li className="roboto font-medium-500">Nov</li>
                    <li className="roboto font-medium-500">Dec</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 card-group">
              <div className="card">
                <div className="card-body charts">
                  <div className="top-heading">
                    <h5 className="card-subtitle roboto">DTC Energy Audit</h5>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showYearPicker
                      dateFormat="yyyy"
                      placeholderText="2021"
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '70%' }}>
                      <div className="contain">
                        <DTCEnergyAuditChart />
                      </div>
                    </div>
                    <div style={{ width: '30%' }} className="dashboard-negative-loss">
                      {/* <span className="title"><b>Negative Loses </b></span> */}
                      <ul>
                        <li>
                          <span style={{ width: '60%' }}>Negative Loses</span><span style={{ width: '40%' }}  >67</span>
                        </li>
                        <li>
                          <span>0 - 5%</span><span>2467</span>
                        </li>
                        <li>
                          <span>5 - 10%</span><span>2987</span>
                        </li>
                        <li>
                          <span>10 - 15%</span><span>6543</span>
                        </li>
                        <li>
                          <span>15 - 20%</span><span>642</span>
                        </li>
                        <li>
                          <span>{'>'} 20%</span><span>32</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ul className="mt-3 year-list">
                    <li className="year-active roboto font-medium-500">Jan</li>
                    <li className="roboto font-medium-500">Feb</li>
                    <li className="roboto font-medium-500">Mar</li>
                    <li className="roboto font-medium-500">Apr</li>
                    <li className="roboto font-medium-500">May</li>
                    <li className="roboto font-medium-500">Jun</li>
                    <li className="roboto font-medium-500">Jul</li>
                    <li className="roboto font-medium-500">Aug</li>
                    <li className="roboto font-medium-500">Sep</li>
                    <li className="roboto font-medium-500">Oct</li>
                    <li className="roboto font-medium-500">Nov</li>
                    <li className="roboto font-medium-500">Dec</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Index;
