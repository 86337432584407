import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import meterImage from '../../assets/img/meter.png';
import doorlockImage from '../../assets/img/doorlock.png';
import newInstallationImage from '../../assets/img/new-installation.png';
import unbilledImage from '../../assets/img/unbilled.png';
import vacantImage from '../../assets/img/vacant.png';
import zerpConsumptionImage from '../../assets/img/zerp-consumption.png';
import mnrImage from '../../assets/img/mnr.png';
import rightarrowImage from '../../assets/img/main-tab-arrow.svg';
import MyInstallations from './Tables/MyInstallations';
import NewInstallations from './Tables/NewInstallations';
import Unbilled from './Tables/Unbilled';
import Vacant from './Tables/Vacant';
import ZeroConsumption from './Tables/ZeroConsumption';
import MNR from './Tables/MNR';
import Doorlock from './Tables/Doorlock';
import { ErrorContext } from '../../context/errorContext';
import { getBillingCount } from '../../services/api/billingService';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const List = (props) => {

  const errorContext = useContext(ErrorContext)

  var components = {
    my_installations: MyInstallations,
    new_installations: NewInstallations,
    unbilled: Unbilled,
    vacant: Vacant,
    zero_consumption: ZeroConsumption,
    mnr: MNR,
    doorlock: Doorlock
  };

  let query = useQuery();
  let history = useHistory();

  let slug = query.get("tab") || 'my_installations';

  let ComponentName = components[query.get("tab") || 'my_installations']

  const [count, setCount] = useState({
    myInstallationCount: "",
    newInstallationCount: "",
    unbilledCount: "",
    mnrCount: "",
    vacantCount: "",
    zeroConsumptionCount: "",
    doorLockCount: ""
  })

  useEffect(() => {
    fetchBillingCount()
  }, [])

  const fetchBillingCount = async () => {
    try {
      const response = await getBillingCount()
      setCount(response.data)
    }
    catch (err) {
      errorContext.showError(err)
    }
  }

  const categoriesArray = [
    {
      name: "My Installations",
      slug: "my_installations",
      counts: count.myInstallationCount,
      icon: meterImage,
    },
    {
      name: "New Installations",
      slug: "new_installations",
      counts: count.newInstallationCount,
      icon: newInstallationImage,
    },
    {
      name: "Unbilled",
      slug: "unbilled",
      counts: count.unbilledCount,
      icon: unbilledImage,
    },
    {
      name: "Vacant",
      slug: "vacant",
      counts: count.vacantCount,
      icon: vacantImage,
    },
    {
      name: "Zero Consumption",
      slug: "zero_consumption",
      counts: count.zeroConsumptionCount,
      icon: zerpConsumptionImage,
    },
    {
      name: "MNR",
      slug: "mnr",
      counts: count.mnrCount,
      icon: mnrImage,
    },
    {
      name: "Doorlock",
      slug: "doorlock",
      counts: count.doorLockCount,
      icon: doorlockImage,
    }
  ];



  const [categories, setCategories] = useState(categoriesArray);

  const redirectHandler = (slug) => {
    history.push('/billing?tab=' + slug);
  }

  return (
    <React.Fragment>
      <div className="my-installation-content">
        <div className="container pl-0 pt-0 pb-0">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <div className="tc-audit-no-list">
                {
                  categoriesArray.map((category, index) => {
                    return (
                      <div onClick={() => redirectHandler(category.slug)} key={index} className={`tc-audit-no-collapse ${slug == category.slug ? 'active' : ''} pt-3 pb-3 ps-2 pe-2`}>
                        <card className="collapse-text">
                          <img className="img-fluid" src={category.icon} />
                          <div className="mb-0 tc-audit-no-title ms-2 w-100">
                            <p className="mb-1 tc-no">{category.name}</p>
                            <p className="mb-0 tc-no-text">{category.counts}</p>
                          </div>
                        </card>
                      </div>
                    );
                  })
                }
              </div>
            </div>
            <ComponentName updateCount={fetchBillingCount}/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default List;