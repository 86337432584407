import React from 'react'

const NoDataFoundRow = (props) => {
  return (
    <React.Fragment>
      <tr><td colSpan={props.colSpan || 12} className='text-center'>{props.message || "No Items  found!"}</td></tr>
    </React.Fragment>
  );
}

export default NoDataFoundRow;