import React, { Fragment, useState, useEffect, useContext } from "react";
import uploadIcon from "../../../assets/img/upload-dark-icon.png";
import downloadIcon from "../../../assets/img/file_download-24px.png";
import filterIcon from '../../../assets/img/filter.png';
import plusIcon from "../../../assets/img/pluse-icon.svg";
import minusIcon from "../../../assets/img/close-icon.svg";
import downArrow from "../../../assets/img/down-filled-triangular-arrow.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Filter from '../../Shared/Filter';
import exclamation from '../../../assets/img/info-tooltip-icon.svg';
import EditIcon from '../../../assets/img/edit-selected-icon@2x.png';
import searchIcon from '../../../assets/img/search-tc-icon.svg';
import { dateFormatter, monthName } from "../../../services/helpers/date";
import NoDataFoundRow from "../../Shared/NoDataFoundRow";
import { ErrorContext } from "../../../context/errorContext";
import { getInstallations } from "../../../services/api/installationsService";
import { mrCode } from "../../../assets/constants";
import FullScreenLoader from "../../Shared/FullScreenLoader";
import Ellipsis from "../Ellipsis";
import { addTCReading } from "../../../services/api/tcService";

const TCDetail = ({ tcDetails }) => {

  const errorContext = useContext(ErrorContext)

  const [readingDate, setReadingDate] = useState(new Date());
  const [tcReading, setTCReading] = useState("")
  const [remarks, setRemarks] = useState("")
  const [tcData, setTCData] = useState(tcDetails)

  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(true)
  const [installations, setInstallations] = useState([]);
  const [activeItem, setActiveItem] = useState('');
  const readingHistory = tcData.readings.filter(item => new Date(item.readingDate).getFullYear() === new Date(startDate).getFullYear())

  // const [readingHistory, setReadingHistory] = useState(readingHistoryArray);

  useEffect(() => {
    fetchInstallationsByTransformerId()
    setTCData(tcDetails)
  }, [tcDetails])

  const fetchInstallationsByTransformerId = async () => {
    try {
      setLoading(true)
      const response = await getInstallations(tcDetails.transformerId)
      setInstallations(response.data)
      setLoading(false)
    }
    catch (err) {
      errorContext.showError(err)
      setLoading(false)
    }
  }



  const addTCReadingHandler = async (e) => {
    try {
      e.preventDefault()
      if (!tcReading && !remarks) {
        errorContext.showError("Reading or Remark is compulsory.")
        return;
      }
      if (tcReading && tcReading < tcData.latestReading.finalValue) {
        errorContext.showError(`Please Enter a Reading greater than ${tcData.latestReading.finalValue}`)
        return;
      }
      const response = await addTCReading(tcDetails.transformerId, {
        readingDate: dateFormatter(readingDate, tcDetails.readingDay),
        ...(tcReading ? { finalValue: Number(tcReading.trim()) } : { failureReason: remarks })
      })
      setTCData(response.data)
    }
    catch (err) {
      errorContext.showError(err)
    }
  }



  // useEffect(() => {
  //   setReadingHistory(readingHistoryArray.slice(0, props.randomNumber));
  //   setInstallations(installationsArray.slice(0, props.randomNumberForInstallations));
  // }, [props]);

  const filterHandler = (e) => {
    e.preventDefault();
    setFilterVisible(!filterVisible);
  }

  const accordianHandler = (event) => {
    event.preventDefault();
    console.log(event.target.type);
    if (event.target.type == 'undefined') return;
    var key = event.currentTarget.getAttribute('data-panel');
    if (activeItem == key) {
      setActiveItem('');
    } else {
      setActiveItem(key);
    }
  }

  const applyFilters = (filters) => {
    console.log(filters);
  }

  const [filterVisible, setFilterVisible] = useState(false);

  return (
    <Fragment>
      <Filter enabledFilters={{ durations: false, tariffs: false, tags: true, remarks: true, mrCodes: false }} isFilterVisible={filterVisible} onFilterClose={filterHandler} onFiltersApply={applyFilters} />
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="comman-head">
            <div className="tc-name-title">
              <h1 className="mb-0">TC No.{tcDetails.number}</h1>
            </div>
          </div>
        </div>
        <div className="col-md-12 consumption-point">
          <hr className="horizontal-line" />
          <div className="consumption-load-section mb-2">
            <span className="mt-1 font-light-300">
              Consumption: <b>{'latestReading' in tcDetails ? tcDetails.latestReading.consumption || '----' : '-'}</b>{" "}
              {/* <img className="img-fluid mb-2 ms-3" src={exclamation} /> */}
            </span>
            <hr className="vertical-line mx-4 mt-2" />
            <span className="mt-1 font-light-300">
              Percentage Loss: <b>{tcDetails.audit.auditStatus && 'currentLoss' in tcDetails ? `${tcDetails.currentLoss.value}%` : '----'}</b>{" "}
              {/* <img className="img-fluid mb-2 ms-3" src={exclamation} /> */}
              {tcDetails.audit.auditStatus && 'currentLoss' in tcDetails ?
                <i class="fas fa-info-circle" style={{ color: "grey" }} 
                  title={`LOSS DETAILS\nMNR : ${tcDetails.audit.mnr}\nDoorLock : ${tcDetails.audit.doorLock}\nZero Consumption :  ${tcDetails.audit.zeroConsumption}\nVacant : ${tcDetails.audit.vacant}\nUnbilled : ${tcDetails.audit.unbilled}`}></i>
                : ''}
            </span>
          </div>
        </div>
        <div className="col-md-12">
          <div className="main-tc-name-form mb-3">
            <div className="main-final-reading-div custom-final-reading-divs">
              <div className="final-reading-title main-reading-custom-title">
                <p className="mb-0 font-medium-500">
                  Final Reading
                </p>
                <form className="month">
                  <DatePicker
                    dateFormat="MMMM yyyy"
                    showTwoColumnMonthYearPicker
                    showMonthYearPicker
                    placeholderText="Select Month"
                    selected={readingDate}
                    onChange={(date) => setReadingDate(new Date(date))}
                  />
                </form>
              </div>
              <form className="final-reading-form">
                <div className="forms-input">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="form-label font-regular-400">Enter Reading</label>
                      <input
                        className="form-control"
                        value={tcReading}
                        onChange={e => { e.target.validity.valid ? setTCReading(e.target.value) : setTCReading(tcReading) }}
                        type="text"
                        pattern="[0-9]*"
                        disabled={remarks ? true : false}
                        placeholder="Enter Reading"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="form-label font-regular-400">Select Remarks</label>
                      <select
                        class="form-select"
                        aria-label="Select Remarks"
                        value={remarks}
                        onChange={e => setRemarks(e.target.value)}
                        disabled={tcReading ? true : false}

                      >
                        <option value="">Select Remarks</option>
                        <option value="METER_NOT_RECORDING">Meter Not Recording</option>
                        <option value="CT_BURNT_OUT">CT Burnt out</option>
                        <option value="NO_DISPLAY">No Display</option>
                        <option value="OTHERS">Others</option>
                      </select>
                    </div>
                    <div className="col-md-4 mr-save-btn">
                      <button type="submit" className="btn btn-primary font-regular-400" onClick={addTCReadingHandler}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {loading ?
              <FullScreenLoader />
              : <div className="history-section mt-3 mb-3 p-0">
                <div className="history-collapse  pt-3 pb-3 pe-4 ps-4">
                  <a
                    onClick={(e) => { e.preventDefault() }}
                    className="collapse-text"
                    data-bs-toggle="collapse"
                    href="#collapseAudithistory"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseAudithistory">
                    <div className="tc-audit-history-collpase-title">
                      <p className="mb-0 font-medium-500">Reading History</p>
                    </div>
                    <div className="select-icon">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showYearPicker
                        showThreeColumnYearPicker
                        dateFormat="yyyy"
                        placeholderText="2021"
                      />
                      {/* <img className="img-fluid ms-2" src={plusIcon} /> */}
                      <img className="img-fluid ms-2" src={minusIcon} />
                    </div>
                  </a>
                </div>
                <div className="collapse collapse-detail show" id="collapseAudithistory">
                  <div className="audit-history-list">
                    <table className="table  mb-0 table-responsive">
                      <thead>
                        <tr>
                          <th className="ps-4 font-medium-500" scope="col">
                            Month
                          </th>
                          <th scope="col">IR</th>
                          <th scope="col">FR</th>
                          <th scope="col">Consumption / Remarks</th>
                          <th scope="col">% Loss</th>
                          <th scope="col" className="text-right pr-20">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {readingHistory.length === 0 ? <NoDataFoundRow message="No Readings for this year." /> :
                          (readingHistory.map((reading, index) => {
                            const finalValueExists = 'finalValue' in reading;
                            const consumptionExists = 'consumption' in reading;
                            return (
                              <tr key={index}>
                                <td className="ps-4 font-regular-400" scope="row">{monthName[new Date(reading.readingDate).getMonth()]}</td>
                                <td>{ finalValueExists ? reading.finalValue : reading.failureReason}</td>
                                <td>{(readingHistory[index + 1]?.finalValue || readingHistory[index + 1]?.failureReason)  ?? "----"}</td>
                                <td>{readingHistory[index + 1]?.consumption ?? readingHistory[index + 1]?.failureReason ?? '----'}</td>
                                <td>{(tcDetails.audit.auditStatus && readingHistory[index + 1]?.lossPercentage ? `${readingHistory[index + 1]?.lossPercentage}%`:'----')}</td>
                                <td className="text-right pr-20">
                                  <a href="#">
                                    <img className="img-fluid icon-width-height" src={EditIcon} />
                                  </a>
                                </td>
                              </tr>
                            )
                          })).reverse()
                        }
                        {/* {readingHistory.length === 0 ? <NoDataFoundRow message="No Readings for this year." /> :
                          (readingHistory.map((reading, index) => {
                            const finalValueExists = 'finalValue' in reading;
                            const consumptionExists = 'consumption' in reading;
                            return (
                              <tr key={index}>
                                <td className="ps-4 font-regular-400" scope="row">{monthName[new Date(reading.readingDate).getMonth()]}</td>
                                <td>{index !== 0 ?
                                  'finalValue' in tcData.readings[index - 1] ? tcData.readings[index - 1].finalValue : tcData.readings[index - 1].failureReason
                                  : '----'}</td>
                                <td>{finalValueExists ? reading.finalValue : '----'}</td>
                                <td>{consumptionExists ? reading.consumption : reading.failureReason}</td>
                                <td>{tcDetails.audit.auditStatus && 'lossPercentage' in reading ? `${reading.lossPercentage}%` : '----'}</td>
                                <td className="text-right pr-20">
                                  <a href="#">
                                    <img className="img-fluid icon-width-height" src={EditIcon} />
                                  </a>
                                </td>
                              </tr>
                            )
                          })).reverse()
                        } */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>}
            <div className="installation-list list-view-box">
              <div className="digit-installation-title mb-3">
                <h3 className="mb-0 ms-3 font-medium-500">
                  {installations.length} Installations</h3>
              </div>
              <div className="search-heading mb-4 pl-0">
                <div className="search-space position-relative me-auto ms-3">
                  <input
                    type="text"
                    placeholder="Search by RR Number"
                    className="form-control"
                  />
                  <img className="img-fluid fa" src={searchIcon} />
                </div>
                <div className="download-upload">
                  <button type="button" className="btn btn-primary download-btn">
                    <img src={downloadIcon} className="me-2" />
                    <p className="mb-0 font-regular-400">Download</p>
                  </button>
                  <button onClick={filterHandler} type="button" className="btn btn-primary filter-btn">
                    <img src={filterIcon} className="" width="18" height="12" />Filter
                  </button>
                </div>
              </div>
              <div className="installing-list">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="ps-4" scope="col">
                          #
                        </th>
                        <th scope="col">MR Code</th>
                        <th scope="col">Reading Day</th>
                        <th scope="col">Account ID</th>
                        <th scope="col">RR Number</th>
                        <th scope="col">Tariff</th>
                        <th scope="col">Consumer Name</th>
                        <th scope="col">Sanction Load</th>
                        <th scope="col">TC Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        installations.length === 0 ? <NoDataFoundRow message="No Installations Found!" />
                          :
                          installations.map((installation, index) => {
                            return (
                              <tr key={index}>
                                <td className="ps-4" scope="row">{index + 1}</td>
                                <td>{mrCode}</td>
                                <td style={{ textAlign: 'center' }}>{installation.readingDay}</td>
                                <td>{installation.accountNumber}</td>
                                <td>{installation.rrNumber}</td>
                                <td>{installation.tariff}</td>
                                <Ellipsis>{installation.consumerName}</Ellipsis>
                                <td>{installation.sanctionedLoad.kW} KW</td>
                                <td>TC -{installation.transformer.number}</td>
                              </tr>
                            )
                          })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <nav className="float-end" aria-label="Page navigation example">
            <ul className="pagination mb-0">
              <li className="page-item next-item">
                <a className="page-link border-0" href="#">
                  Previous
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item previous-item mx-3">
                <span>Out of 100</span>
                <a className="page-link border-0 active" href="#">
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Fragment>
  );
};

export default TCDetail;
