import React, { useState } from "react";
import closeIcon from '../../assets/img/close-icon1.svg'

const Filter = ({ isFilterVisible = false,
  onFilterClose = () => { },
  onChange = () => { },
  value = "",
  onReset = () => { },
  onApply = () => { }
}) => {

  const [activeDuration, setActiveDuration] = useState('');
  const [activeTariff, setActiveTariff] = useState('');
  const [activeTag, setActiveTag] = useState('');
  const [activeRemark, setActiveRemark] = useState({});
  const [activeMRCode, setActiveMRCode] = useState({});

  const [activeFilters, setActiveFilters] = useState({});

  /** Duration */
  const durationsArray = [
    {
      value: 1,
      name: '> 1 Month'
    },
    {
      value: 2,
      name: '> 2 Months'
    },
    {
      value: 3,
      name: '> 3 Months'
    },
    {
      value: 4,
      name: '> 4 Months'
    },
    {
      value: 5,
      name: '> 5 Months'
    }
  ];
  const [durations, setDurations] = useState(durationsArray);

  const tariffsArray = [
    {
      value: 2,
      name: 'LT 2'
    },
    {
      value: 3,
      name: 'LT 3'
    },
    {
      value: 4,
      name: 'LT 4'
    },
    {
      value: 5,
      name: 'LT 5'
    },
    {
      value: 6,
      name: 'LT 6'
    },
    {
      value: 7,
      name: 'LT 7'
    },
    // {
    //   value: 'all',
    //   name: 'All'
    // }
  ];
  const [tariffs, setTariffs] = useState(tariffsArray);

  const remarksArray = [
    {
      value: 2,
      name: 'Meter Removed'
    },
    {
      value: 3,
      name: 'Disconnected'
    },
    {
      value: 4,
      name: 'Vacant'
    },
    {
      value: 5,
      name: 'Pole Disconnected'
    },
    {
      value: 6,
      name: 'Govt. Installation'
    },
    {
      value: 'all',
      name: 'Amount Paid'
    }
  ];
  const [remarks, setRemarks] = useState(remarksArray);

  const mrCodeArray = [
    {
      value: 2,
      name: '12232323'
    },
    {
      value: 3,
      name: '2332323'
    },
    {
      value: 4,
      name: '98129812'
    },
    {
      value: 5,
      name: '12812'
    },
    {
      value: 6,
      name: '1287321'
    },
    {
      value: 'all',
      name: '124445'
    }
  ];
  const [mrCodes, setMrCodes] = useState(mrCodeArray);

  const tagsArray = [
    {
      value: 'tagged',
      name: 'Tagged'
    },
    {
      value: 'untagged',
      name: 'Untagged'
    }
  ];

  const executionType = ['self', 'department'
  ];
  const [tags, setTags] = useState(tagsArray);

  const filterSelectionHandler = (event) => {
    event.preventDefault();
    let dataSets = event.target.dataset;
    switch (dataSets.type) {
      case 'duration':
        setActiveDuration(dataSets.value);
        break;
      case 'tariff':
        setActiveTariff(dataSets.value);
        break;
      case 'remark':
        setActiveRemark(dataSets.value);
        break;
      case 'tag':
        setActiveTag(dataSets.value);
        break;
      case 'mr-code':
        setActiveMRCode(dataSets.value);
        break;
    }
    const activeFiltersObject = {
      ...activeFilters,
      [dataSets.type]: dataSets.value
    }
    setActiveFilters(activeFiltersObject);
  }

  // const onFiltersApply = () => {
  //   props.onFiltersApply(activeFilters);
  // }

  const resetFilters = (event) => {
    setActiveDuration('');
    setActiveTariff('');
    setActiveRemark('');
    setActiveFilters({});
  }

  return (
    <React.Fragment>
      <div className={`overlay-filter ${isFilterVisible ? '' : 'd-none'}`}></div>
      <div className={`filter-bar ${isFilterVisible ? '' : 'd-none'}`}>
        <div style={{ height: '100%', position: 'relative' }}>
          <div className="row">
            <div className="col-md-12 ps-0 pe-0">
              <div className="header">
                <ul className="mb-0 ps-0">
                  <li className="me-auto font-bold font-20 filter-title">Filter</li>
                  <li onClick={onFilterClose}>
                    <img className="img-fluid" src={closeIcon} />
                  </li>
                </ul>
              </div>
            </div>
            {

              <div className="col-md-12">
                <div className="month-tab">
                  <p className="mb-2">Execution Type</p>
                  <div className="tabs">
                    {
                      executionType.map((type, index) => {
                        return (
                          <a
                            onClick={() => onChange(type)}
                            className={value == type ? 'custom-active' : ''}
                            style={{ textTransform: 'capitalize' }}>
                            {type}</a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            }

            {

              <div className="col-md-12">
                <div className="month-tab">
                  <p className="mb-2">Tariff</p>
                  <div className="tabs">
                    {
                      tariffs.map((type, index) => {
                        return (
                          <a
                            className={false ? 'custom-active' : ''}
                            style={{ textTransform: 'capitalize' }}>
                            {type.name}</a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            }
            {

              <div className="col-md-12">
                <div className="month-tab">
                  <p className="mb-2">Analysis</p>
                  <div className="tabs">
                    {
                      ['Unbilled', 'MNR', 'Vacant', 'Zero Consumption', 'DoorLock'].map((type, index) => {
                        return (
                          <a
                            className={false ? 'custom-active' : ''}
                            style={{ textTransform: 'capitalize' }}>
                            {type}</a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            }

            {/* {
              props.enabledFilters.durations &&
              <div className="col-md-12">
                <div className="month-tab">
                  <p className="mb-2">Duration</p>
                  <div className="tabs">
                    {
                      durations.map((duration, index) => {
                        return (
                          <a data-value={duration.value} data-type="duration" onClick={filterSelectionHandler} href="#" className={activeDuration == duration.value ? 'custom-active' : ''}>{duration.name}</a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            }
            {
              props.enabledFilters.mrCodes &&
              <div className="col-md-12">
                <div className="month-tab">
                  <p className="mb-2 mr-title">MR Code</p>
                  <div className="tabs">
                    {
                      mrCodes.map((mrCode, index) => {
                        return (
                          <a data-value={mrCode.value} data-type="mr-code" onClick={filterSelectionHandler} href="#" className={activeMRCode == mrCode.value ? 'custom-active' : ''}>{mrCode.name}</a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            }
            {props.enabledFilters.tariffs &&
              <div className="col-md-12">
                <div className="tariff-tab">
                  <p className="mb-2">Tariff</p>
                  <div className="tabs">
                    {
                      tariffs.map((tariff, index) => {
                        return (
                          <a data-value={tariff.value} data-type="tariff" onClick={filterSelectionHandler} href="#" className={activeTariff == tariff.value ? 'custom-active' : ''}>{tariff.name}</a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            }
            {props.enabledFilters.tags &&
              <div className="col-md-12">
                <div className="tariff-tab">
                  <p className="mb-2">TC's</p>
                  <div className="tabs">
                    {
                      tags.map((tag, index) => {
                        return (
                          <a data-value={tag.value} data-type="tag" onClick={filterSelectionHandler} href="#" className={activeTag == tag.value ? 'custom-active' : ''}>{tag.name}</a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            }
            {props.enabledFilters.remarks &&
              <div className="col-md-12">
                <div className="remark-tab">
                  <p className="mb-2">Remark</p>
                  <div className="tabs">
                    {
                      remarks.map((remark, index) => {
                        return (
                          <a data-value={remark.value} data-type="remark" onClick={filterSelectionHandler} href="#" className={activeRemark == remark.value ? 'remark-active' : ''}>{remark.name}</a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            } */}
          </div>
          <div className="main-reset-apply-section">
            <div className="reset-apply-btn text-right pe-3">
              <button className="reset-btn" onClick={onReset}>Reset</button>
              <button className="apply-btn apply-btn-active" onClick={onApply}>Apply</button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Filter;
