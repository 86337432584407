export const fetchSectionAndDivisionCode = (inputData) => {
    const data = JSON.parse(localStorage.getItem('CognitoIdentityServiceProvider.2n7lb6sq1dus7jii9bl09fpasp.150001.userData'))
    if (!data) return "Data Not Found!"
    if (inputData === 'sectionCode') return data.UserAttributes[2].Value
    if (inputData === 'subDivisionCode') return data.UserAttributes[1].Value
}

export const fetchAEDetails = (inputData) => {
    const data = JSON.parse(localStorage.getItem('CognitoIdentityServiceProvider.2n7lb6sq1dus7jii9bl09fpasp.150001.userData'))
    if (!data) return "AE Details Not Found!"
    if (inputData === 'name') return data.UserAttributes[4].Value
    // if (inputData === 'subDivisionCode') return data.UserAttributes[1].Value
}