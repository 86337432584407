import React from 'react';
import Colors from '../../assets/scss/variable.scss'



const FullScreenLoader = () => {
    return ( 
        <div style={styles.root}>
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status" style={{ color: Colors.secondaryColor }}>
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
     );
}


const styles = {
    root:{
        display:"flex",
        flex:1,
        height:'100%',
        minHeight:"88vh",
        backgroundColor:"white",
        alignItems:"center",
        justifyContent:"center"
    }
}
 
export default FullScreenLoader;