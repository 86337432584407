import React, { Fragment, useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import filterImage from "../../assets/img/filter.png";
import filterIcon from '../../assets/img/filter.png';
import downloadImage from "../../assets/img/file_download-24px.png";
import backArrow from "../../assets/img/back-arrow.png";
import { Link } from "react-router-dom";
import EditIcon from '../../assets/img/edit-selected-icon@2x.png';
import ViewImage from '../../assets/img/view-selected-icon@2x.png';
import Delete from '../../assets/img/delete-selected-icon@2x.png';
import exclamation from '../../assets/img/info-tooltip-icon.svg';
import closeIcon from '../../assets/img/close-icon.svg';
import searchIcon from '../../assets/img/search-tc-icon.svg';
import { getInstallations } from "../../services/api/installationsService";
import FullScreenLoader from "../Shared/FullScreenLoader";
import NoDataFoundRow from "../Shared/NoDataFoundRow";
import { ErrorContext } from "../../context/errorContext";
import { dateFormatter, monthName } from "../../services/helpers/date";
import Ellipsis from "./Ellipsis";
import { addTCReading } from "../../services/api/tcService";
import Filter from "../Shared/Filter";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TCAuditDetail = () => {

  let query = useQuery();
  let slug = query.get("status") || 'unaudited';

  const location = useLocation()
  const errorContext = useContext(ErrorContext)
  const tcDetails = location.state.transformerData
  const [tcData, setTCData] = useState(tcDetails)



  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false)
  const [installations, setInstallations] = useState([])
  const readingHistory = tcData.readings.filter(item => new Date(item.readingDate).getFullYear() === new Date(startDate).getFullYear())

  const [tcReading,setTCReading] = useState("")
  const [remarks, setRemarks] = useState("")
  const [readingDate,setReadingDate] = useState(new Date())

  const [filterVisibility,setFilterVisibility]  = useState(false)



  useEffect(() => {
    fetchInstallationsByTransformerId()
  }, [])

  const fetchInstallationsByTransformerId = async () => {
    try {
      setLoading(true)
      const response = await getInstallations(tcDetails.transformerId)
      setInstallations(response.data)
      setLoading(false)
    }
    catch (err) {
      errorContext.showError(err)
      setLoading(false)
    }
  }

  const addTCReadingHandler = async (e) => {
    try {
      e.preventDefault()
      if (!tcReading && !remarks) {
        errorContext.showError("Reading or Remark is compulsory.")
        return;
      }
      if (tcReading && tcReading < tcData.latestReading.finalValue) {
        errorContext.showError(`Please Enter a Reading greater than ${tcData.latestReading.finalValue}`)
        return;
      }
      const response = await addTCReading(tcDetails.transformerId, {
        readingDate: dateFormatter(readingDate,tcDetails.readingDay),
        ...(tcReading ? { finalValue: Number(tcReading.trim()) } : { failureReason: remarks })
      })
      setTCData(response.data)
    }
    catch (err) {
      errorContext.showError(err)
    }
  }

  return (
    <Fragment>
      <Filter
        isFilterVisible={filterVisibility}
        onFilterClose={()=>setFilterVisibility(false)}
      />
      <div class="tc-audit-content">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">


                  <div className="row">
                    <div class="col-12 col-md-6">
                      <div class="tc-name-title">
                        <h1 className="mb-0">TC No. {tcDetails.number}</h1>
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="comman-head justify-content-end">
                        <div class="back-btn">
                          <Link to="/tc-audit" class="btn btn-primary">
                            <img
                              src={backArrow}
                              style={{ width: "24px", marginRight: "10px" }}
                            />
                            <p class="mb-0 me-1 font-regular-400">BACK</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 consumption-point">
                  <hr class="horizontal-line" />
                  <div class="consumption-load-section mt-3 mb-2">
                    <span className="font-light-300">
                      Consumption: <b className="font-medium-500">{tcDetails?.latestReading?.consumption ?? '-----'}</b>{" "}
                      {/* <img className="img-fluid mb-2 ms-3" src={exclamation} /> */}
                    </span>
                    <hr class="vertical-line mx-4 mt-2" />
                    <span className="font-light-300">
                      Percentage Loss: <b className="font-medium-500">{tcDetails.audit.auditStatus && 'currentLoss' in tcDetails ? `${tcDetails.currentLoss.value}%` : '----'}</b>{" "}
                      {tcDetails.audit.auditStatus && 'currentLoss' in tcDetails ?
                        <i class="fas fa-info-circle" style={{ color: "grey" }}
                          title={`LOSS DETAILS\nMNR : ${tcDetails.audit.mnr}\nDoorLock : ${tcDetails.audit.doorLock}\nZero Consumption :  ${tcDetails.audit.zeroConsumption}\nVacant : ${tcDetails.audit.vacant}\nUnbilled : ${tcDetails.audit.unbilled}`}></i>
                        : ''}
                    </span>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="main-tc-name-form mb-3">
                    <div class="main-final-reading-div custom-final-reading">
                      <div class="final-reading-title">
                        <p class="mb-0 font-medium-500">Final Reading</p>
                        <form>
                          <DatePicker
                            dateFormat="MMMM yyyy"
                            showMonthYearPicker
                            showTwoColumnMonthYearPicker
                            placeholderText="Select Month"
                            selected={readingDate}
                            onChange={(date) => setReadingDate(new Date(date))}
                          />
                        </form>
                      </div>
                      <form className="final-reading-form">
                        <div className="forms-input">
                          <div className="row">
                            <div className="col-md-4">
                              <label className="form-label font-regular-400">Enter Reading</label>
                              <input
                                className="form-control"
                                value={tcReading}
                                onChange={e => { e.target.validity.valid ? setTCReading(e.target.value) : setTCReading(tcReading) }}
                                type="text"
                                pattern="[0-9]*"
                                disabled={remarks ? true : false}
                                placeholder="Enter Reading"
                              />
                            </div>
                            <div class="col-md-4">
                              <label class="form-label font-regular-400">Select Remarks</label>
                              <select
                                class="form-select"
                                aria-label="Select Remarks"
                                value={remarks}
                                onChange={e => setRemarks(e.target.value)}
                                disabled={tcReading ? true : false}

                              >
                                <option value="">Select Remarks</option>
                                <option value="METER_NOT_RECORDING">Meter Not Recording</option>
                                <option value="CT_BURNT_OUT">CT Burnt out</option>
                                <option value="NO_DISPLAY">No Display</option>
                                <option value="OTHERS">Others</option>
                              </select>
                            </div>
                            <div className="col-md-4 mr-save-btn">
                              <button type="submit" className="btn btn-primary font-regular-400" onClick={addTCReadingHandler}>
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="history-section mt-3 mb-3 pe-0 ps-0 pt-0 pb-0">
                      <div class="history-collapse pt-3 pb-3">
                        <a
                          class="collapse-text"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseAudithistory"
                        >
                          <div class="tc-audit-history-collpase-title ps-4">
                            <p class="mb-0 font-medium-500">Reading History</p>
                          </div>
                          <div class="select-icon pe-4">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              showYearPicker
                              dateFormat="yyyy"
                              placeholderText="2021" />
                            <img className="fa img-fluid" src={closeIcon} />
                            {/* <i class="fa fa-minus" aria-hidden="true"></i> */}
                          </div>
                        </a>
                      </div>
                      <div class="collapse collapse-detail show" id="collapseAudithistory">
                        <div class="audit-history-list">
                          <div className="table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th className="ps-4 font-medium-500" scope="col">Month</th>
                                  <th scope="col" className="font-medium-500">IR</th>
                                  <th scope="col" className="font-medium-500">FR</th>
                                  <th scope="col" className="font-medium-500">Consumption/Remarks</th>
                                  <th scope="col" className="font-medium-500">Per % Loss</th>
                                  <th scope="col" className="font-medium-500 text-right pr-20">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {readingHistory.length === 0 ? <NoDataFoundRow message="No Readings for this year." /> :
                                  (readingHistory.map((reading, index) => {
                                    const finalValueExists = 'finalValue' in reading;
                                    const consumptionExists = 'consumption' in reading;
                                    return (
                                      <tr key={index}>
                                        <td className="ps-4 font-regular-400" scope="row">{monthName[new Date(reading.readingDate).getMonth()]}</td>
                                        <td>{finalValueExists ? reading.finalValue : reading.failureReason}</td>
                                        <td>{(readingHistory[index + 1]?.finalValue || readingHistory[index + 1]?.failureReason) ?? "----"}</td>
                                        <td>{readingHistory[index + 1]?.consumption ?? readingHistory[index + 1]?.failureReason ?? '----'}</td>
                                        <td>{(tcDetails.audit.auditStatus && readingHistory[index + 1]?.lossPercentage ? `${readingHistory[index + 1]?.lossPercentage}%` : '----')}</td>
                                        <td className="text-right pr-20">
                                          <a href="#">
                                            <img className="img-fluid icon-width-height" src={EditIcon} />
                                          </a>
                                        </td>
                                      </tr>
                                    )
                                  })).reverse()
                                }
                                {/* <tr>
                                  <td className="ps-4 font-regular-400" scope="row">
                                    November
                                  </td>
                                  <td className="font-regular-400">12345</td>
                                  <td className="font-regular-400">12345</td>
                                  <td className="font-regular-400">540 Units</td>
                                  <td className="font-regular-400">0.5 %</td>
                                  <td className="text-right pr-20">
                                    <a href="#">
                                      <img className="img-fluid icon-width-height" src={EditIcon} />
                                    </a>
                                  </td>
                                </tr> */}
                                {/* <tr>
                                  <td className="ps-4 font-regular-400" scope="row">
                                    October
                                  </td>
                                  <td className="font-regular-400">12345</td>
                                  <td className="font-regular-400">12345</td>
                                  <td className="font-regular-400">540 Units</td>
                                  <td className="font-regular-400">0.5 %</td>
                                  <td className="text-right pr-20">
                                    <a href="#">
                                      <img className="img-fluid icon-width-height" src={EditIcon} />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="ps-4 font-regular-400" scope="row">
                                    September
                                  </td>
                                  <td className="font-regular-400">12345</td>
                                  <td className="font-regular-400">12345</td>
                                  <td className="font-regular-400">540 Units</td>
                                  <td className="font-regular-400">0.5 %</td>
                                  <td className="text-right pr-20">
                                    <a href="#">
                                      <img className="img-fluid icon-width-height" src={EditIcon} />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="ps-4 font-regular-400" scope="row">
                                    August
                                  </td>
                                  <td className="font-regular-400">12345</td>
                                  <td className="font-regular-400">12345</td>
                                  <td className="font-regular-400">540 Units</td>
                                  <td className="font-regular-400">0.5 %</td>
                                  <td className="text-right pr-20">
                                    <a href="#">
                                      <img className="img-fluid icon-width-height" src={EditIcon} />
                                    </a>
                                  </td>
                                </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    {slug === 'unaudited' && false &&
                      (
                        <div className="count-installation-list list-view-box">
                          <div className="digit-installation-title mb-3">
                            <h3 className="mb-0 ms-3">
                              560 Installations</h3>
                          </div>
                          <div className="search-heading mb-4 pl-0">
                            <div className="search-space position-relative me-auto">
                              <input
                                type="text"
                                placeholder="Search"
                                className="form-control"
                              />
                              <img className="fa img-fluid" src={searchIcon} />
                              {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                            </div>
                            <div className="download-upload">
                              <button
                                type="button"
                                className="btn btn-primary download-btn"
                              >
                                <img src={downloadImage} className="me-2" />
                                <p className="mb-0 font-regular-400">Download</p>
                              </button>
                              <button type="button" className="btn btn-primary filter-btn">
                                <img src={filterIcon} className="" width="18" height="12" />Filter
                              </button>
                            </div>
                          </div>
                          <div className="installing-list list-view-box">
                            <div className="table-responsive">
                              <table className="table mt-0 mb-0">
                                <thead>
                                  <tr>
                                    <th className="ps-4" scope="col">
                                      #
                                    </th>
                                    <th scope="col" className="font-medium-500">MR Code</th>
                                    <th scope="col" className="font-medium-500">Reading Day</th>
                                    <th scope="col" className="font-medium-500">Account ID</th>
                                    <th scope="col" className="font-medium-500">RR Number</th>
                                    <th scope="col" className="font-medium-500">Tariff</th>
                                    <th scope="col" className="font-medium-500">Consumer Name</th>
                                    <th scope="col" className="font-medium-500">Sanction Load</th>
                                    <th scope="col" className="font-medium-500">TC Number</th>
                                  </tr>
                                </thead>
                                <tbody className="tc-number">
                                  <tr>
                                    <td className="ps-4 font-regular-400" scope="row">
                                      1
                                    </td>
                                    <td className="font-regular-400">123456</td>
                                    <td className="font-regular-400">1</td>
                                    <td className="font-regular-400">1234567890</td>
                                    <td className="font-regular-400">4EEH1234</td>
                                    <td className="font-regular-400">LT 3</td>
                                    <td className="font-regular-400">Ganesh Kharatmal</td>
                                    <td className="font-regular-400">2 KW</td>
                                    <td>
                                      <span className="font-medium-500">Tag-TC</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="ps-4 font-regular-400" scope="row">
                                      2
                                    </td>
                                    <td className="font-regular-400">123456</td>
                                    <td className="font-regular-400">1</td>
                                    <td className="font-regular-400">1234567890</td>
                                    <td className="font-regular-400">4EEH1234</td>
                                    <td className="font-regular-400">LT 3</td>
                                    <td className="font-regular-400">Ganesh Kharatmal</td>
                                    <td className="font-regular-400">2 KW</td>
                                    <td>
                                      <span className="font-medium-500">Tag-TC</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="ps-4 font-regular-400" scope="row">
                                      3
                                    </td>
                                    <td className="font-regular-400">123456</td>
                                    <td className="font-regular-400">1</td>
                                    <td className="font-regular-400">1234567890</td>
                                    <td className="font-regular-400">4EEH1234</td>
                                    <td className="font-regular-400">LT 3</td>
                                    <td className="font-regular-400">Ganesh Kharatmal</td>
                                    <td className="font-regular-400">2 KW</td>
                                    <td>
                                      <span className="font-medium-500">Tag-TC</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="ps-4 font-regular-400" scope="row">
                                      4
                                    </td>
                                    <td className="font-regular-400">123456</td>
                                    <td className="font-regular-400">1</td>
                                    <td className="font-regular-400">1234567890</td>
                                    <td className="font-regular-400">4EEH1234</td>
                                    <td className="font-regular-400">LT 3</td>
                                    <td className="font-regular-400">Ganesh Kharatmal</td>
                                    <td className="font-regular-400">2 KW</td>
                                    <td>
                                      <span className="font-medium-500">Tag-TC</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="ps-4 font-regular-400" scope="row">
                                      5
                                    </td>
                                    <td className="font-regular-400">123456</td>
                                    <td className="font-regular-400">1</td>
                                    <td className="font-regular-400">1234567890</td>
                                    <td className="font-regular-400">4EEH1234</td>
                                    <td className="font-regular-400">LT 3</td>
                                    <td className="font-regular-400">Ganesh Kharatmal</td>
                                    <td className="font-regular-400">2 KW</td>
                                    <td>
                                      <span className="font-medium-500">Tag-TC</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    }
                    {(slug === 'audited' || slug === 'unaudited') &&
                      (
                        <div className="unaudited-count-installation-list list-view-box">
                          <div className="digit-installation-title mb-3">
                            <h3 className="mb-0 ms-3">
                              {installations.length} Installations</h3>
                          </div>
                          <div className="search-heading">
                            <div className="search-space position-relative me-auto ms-3">
                              <input
                                type="text"
                                placeholder="Search"
                                className="form-control"
                              />
                              <img className="fa img-fluid" src={searchIcon} />
                              {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                            </div>
                            <div className="download-upload">
                              <button
                                type="button"
                                className="btn btn-primary download-btn"
                              >
                                <img src={downloadImage} className="me-2" />
                                <p className="mb-0 font-regular-400">Download</p>
                              </button>
                            <button type="button" className="btn btn-primary filter-btn" onClick={() => setFilterVisibility(true)}>
                                <img src={filterIcon} className="" width="18" height="12" />Filter
                              </button>
                            </div>
                          </div>
                          <div className="installing-list">
                            <div className="table-responsive">
                              <table className="table mt-0 mb-0">
                                <thead>
                                  <tr>
                                    <th className="ps-4" scope="col">
                                      #
                                    </th>
                                    <th className="font-medium-500" scope="col">Account ID</th>
                                    <th className="font-medium-500" scope="col">Consumer Name</th>
                                    <th className="font-medium-500" scope="col">RR Number</th>
                                    <th className="font-medium-500" scope="col">Consumption</th>
                                    <th className="font-medium-500" scope="col">Remarks</th>
                                    <th className="font-medium-500 text-right pr-20" scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody className="tc-number">
                                  {loading ?
                                    <FullScreenLoader /> :
                                    installations.length === 0 ?
                                      <NoDataFoundRow /> :
                                      installations.map((installation, index) => (<tr key="index">
                                        <td className="ps-4 font-regular-400" scope="row">
                                          {index + 1}
                                        </td>
                                        <td className="font-regular-400">{installation.accountNumber}</td>
                                        <Ellipsis>{installation.consumerName}</Ellipsis>
                                        <td className="font-regular-400">{installation.rrNumber}</td>
                                        <td className="font-regular-400">{installation.latestReading.consumption} Units</td>
                                        <td className="font-regular-400">N/A</td>
                                        <td className="text-right">
                                          <a href="">
                                            <img className="img-fluid icon-width-height" src={ViewImage} />
                                          </a>
                                          {/* <a href="">
                                            <img className="img-fluid icon-width-height" src={EditIcon} />
                                          </a>
                                          <a href="">
                                            <img className="img-fluid icon-width-height" src={Delete} />
                                          </a> */}
                                        </td>
                                      </tr>))
                                  }
                                  {/* <tr>
                                    <td className="ps-4 font-regular-400" scope="row">
                                      2
                                    </td>
                                    <td className="font-regular-400">1234567890</td>
                                    <td className="font-regular-400">4EEH1234</td>
                                    <td className="font-regular-400">540 Units</td>
                                    <td className="font-regular-400">New RR Number</td>
                                    <td className="text-right">
                                      <a href="">
                                        <img className="img-fluid icon-width-height" src={ViewImage} />
                                      </a>
                                      <a href="">
                                        <img className="img-fluid icon-width-height" src={EditIcon} />
                                      </a>
                                      <a href="">
                                        <img className="img-fluid icon-width-height" src={Delete} />
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="ps-4 font-regular-400" scope="row">
                                      3
                                    </td>
                                    <td className="font-regular-400">1234567890</td>
                                    <td className="font-regular-400">4EEH1234</td>
                                    <td className="font-regular-400">540 Units</td>
                                    <td className="font-regular-400">New RR Number</td>
                                    <td className="text-right">
                                      <a href="">
                                        <img className="img-fluid icon-width-height" src={ViewImage} />
                                      </a>
                                      <a href="">
                                        <img className="img-fluid icon-width-height" src={EditIcon} />
                                      </a>
                                      <a href="">
                                        <img className="img-fluid icon-width-height" src={Delete} />
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="ps-4 font-regular-400" scope="row">
                                      4
                                    </td>
                                    <td className="font-regular-400">1234567890</td>
                                    <td className="font-regular-400">4EEH1234</td>
                                    <td className="font-regular-400">540 Units</td>
                                    <td className="font-regular-400">New RR Number</td>
                                    <td className="text-right">
                                      <a href="">
                                        <img className="img-fluid icon-width-height" src={ViewImage} />
                                      </a>
                                      <a href="">
                                        <img className="img-fluid icon-width-height" src={EditIcon} />
                                      </a>
                                      <a href="">
                                        <img className="img-fluid icon-width-height" src={Delete} />
                                      </a>
                                    </td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <nav class="float-end" aria-label="Page navigation example">
                    <ul class="pagination mb-0">
                      <li class="page-item next-item">
                        <a class="page-link border-0" href="#">
                          Previous
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li class="page-item previous-item mx-3">
                        <span>Out of 100</span>
                        <a class="page-link border-0 active" href="#">
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TCAuditDetail;
