import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import NoDataFoundRow from '../Shared/NoDataFoundRow';
import DeleteIcon from '../../assets/img/delete-selected-icon@2x.png';
import EditIcon from '../../assets/img/edit-selected-icon@2x.png';
import AddIcon from '../../assets/img/add-icon@2x.png';
import searchIcon from '../../assets/img/search-tc-icon.svg';
import { getAllMRs } from '../../services/api/mrManagementService';
import { dateInDDMMYYYY } from '../../services/helpers/date';
import { fetchSectionAndDivisionCode } from '../../services/helpers/meta';
import { ErrorContext } from '../../context/errorContext';
import FullScreenLoader from '../Shared/FullScreenLoader';

export default function List() {


  const [mrList, setMRList] = useState([])
  const [loading, setLoading] = useState(true)
  const errorContext = useContext(ErrorContext)

  useEffect(() => {
    fetchAllMrs();
  }, []);

  const fetchAllMrs = async () => {
    try {
      setLoading(true)
      const response = await getAllMRs()
      setMRList(response.data)
      setLoading(false)
    }
    catch (err) {
      errorContext.showError(err)
      setLoading(false)
    }
  }


  return (
    <React.Fragment>
      {loading ? <FullScreenLoader /> :
        <div className="management-content">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <h1 className="mb-0">MR Management</h1>
              </div>

              <div className="col-12 col-md-6 text-right">
                <Link to="/mr-management/add" className="btn btn-primary add-new-btn">
                  <img className="icon-width-height-24" src={AddIcon} />&nbsp; Add New MR
              </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 ">
                <hr className="horizontal-line" />
                <div className="code-section">
                  <span>Section Code: <span className="font-medium">{fetchSectionAndDivisionCode('sectionCode')}</span></span>
                  <hr className="vertical-line mx-4" />
                  <span>Sub Division Code: <span className="font-medium">{fetchSectionAndDivisionCode('subDivisionCode')}</span></span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="main-management-table list-view-box mt-2">
                  <div className="search-heading pt-0 pb-2">
                    <div className="col-md-4 position-relative">
                      <input type="text" placeholder="Search MR by name or code" className="form-control mr-0" />
                      <img className="fa img-fluid" src={searchIcon} />
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="pl-15" scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">MR Code</th>
                          <th scope="col">MR Phone Number</th>
                          <th scope="col">Added Date</th>
                          <th scope="col" className="text-right pr-20">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          mrList.map((mr, index) => (
                            <tr key={mr.id}>
                              <td className="pl-15" scope="row">{index + 1}</td>
                              <td>{mr.name}</td>
                              <td>{mr.userInfo.mrCode}</td>
                              <td>{mr.phoneNumber}</td>
                              <td>{dateInDDMMYYYY(mr.createdAt)}</td>
                              <td className="text-right pr-20">
                                <Link to={`/mr-management/${mr.userId}/edit`}>
                                  <img className="img-fluid icon-width-height" src={EditIcon} />
                                </Link>
                                <Link to="/">
                                  <img className="img-fluid icon-width-height mr-0" src={DeleteIcon} />
                                </Link>
                              </td>
                            </tr>
                          ))
                        }
                        {
                          mrList.length == 0 ? <NoDataFoundRow colSpan="6" /> : ""
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <nav aria-label="Page navigation example">
                  <ul className="pagination mb-0">
                    <li className="page-item next-item"><a className="page-link border-0" href="#">Previous</a></li>
                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                    <li className="page-item previous-item"><span>Out of 100</span><a className="page-link border-0 active" href="#">Next</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>}
    </React.Fragment>
  );
}
