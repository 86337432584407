export const dateInDDMMYYYY = (inputDate) => {
    const date = new Date(inputDate)
    return (
        `${date.getDate()}-${(date.getMonth() + 1)}-${date.getFullYear()}`
    )
}

export const monthName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const dateFormatter = (monthAndYear,date) => {
    const year = monthAndYear.getFullYear()
    const month = (monthAndYear.getMonth() < 9) ? `0${monthAndYear.getMonth() + 1}` : monthAndYear.getMonth() + 1
    const day = (date < 10) ? `0${date}` : date
    return `${year}-${month}-${day}`
}