import React, { useState } from 'react';
import { NavLink, Link, Route } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import downArrow from '../../assets/img/user-down-arrow.svg';
import upArrow from '../../assets/img/white-up-arrow.svg';

const SubMenu = (props) => {
  return (
    <ul className={`${props.isShowing ? '' : 'd-none'}`}>
      {
        props.menu.map((submenu, index) => {
          return (
            <li>
              <NavLink key={index} activeClassName="submenu-menu" to={submenu.link}>
                {submenu.title}
              </NavLink>
            </li>
          )
        })
      }
    </ul>
  )
}

const Sidebar = (props) => {
  const SidebarStyle = {
    height: '100%',
    width: '86px',
    position: 'fixed',
    // overflowY: 'scroll'
  };
  const SecondSidebarStyle = {
    height: '89%',
    position: 'fixed',
    display: props.sideBarMinimized ? 'none' : 'block',
    overflowY: 'scroll',
  };

  const [match, setMatch] = useState('');

  const subMenuHandler = (event, menu) => {
    if (menu.hasSubmenu) event.preventDefault();
    setMatch(menu.title);
  }

  return (
    <React.Fragment>
      <aside className={`nav-vertical small-sidebar ${props.sideBarMinimized ? "important" : ""}`} style={SidebarStyle}>
        <div className="nav-wrapper">
          <div className="nav-inner-wrapper">
            <ul>
              {
                SidebarData.map((val, key) => {
                  return (
                    <li key={key}>
                      <NavLink  className="nav-link" to={val.link}>
                        <img src={val.icon} />
                      </NavLink>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </aside>
      <aside className="nav-vertical big-sidebar" style={SecondSidebarStyle}>
        <div className="nav-wrapper">
          <div className="nav-inner-wrapper">
            <ul>
              {
                SidebarData.map((menu, key) => {
                  return (
                    <li key={key}>
                      <NavLink onClick={(e) => subMenuHandler(e, menu)} to={menu.link}>
                        <img src={menu.icon} />
                        <span className="roboto font-regular-400">{menu.title}</span>
                        { menu.hasSubmenu && match !== menu.title && <img className="fa img-fluid" src={downArrow} /> }
                        { menu.hasSubmenu && match === menu.title && <img className="img-fluid fa" src={upArrow} /> }
                      </NavLink>
                      {
                        menu.hasSubmenu && <SubMenu isShowing={match == menu.title ? true : false } menu={menu.subMenu} />
                      }
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </div>
      </aside>
    </React.Fragment>
  );
}

export default Sidebar;

