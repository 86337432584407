import React, { useContext, useState } from 'react';
import { API } from 'aws-amplify'
import { ErrorContext } from '../../context/errorContext';
import FullScreenLoader from '../Shared/FullScreenLoader';


const Settings = () => {

    const errorContext = useContext(ErrorContext)
    const [loading, setLoading] = useState(false)
    const [successMsg, setSuccessMsg] = useState("")


    const handleReset = async () => {
        try {
            setLoading(true)
            await API.post('baseURL', '/ae/recreate')
            setSuccessMsg("Reset Successful!!")
            setLoading(false)
        }
        catch (err) {
            errorContext.showError(err)
            setLoading(false)
        }
    }


    return (
        <div style={{ display: "flex", flex: 1, alignItems: 'center', justifyContent: 'center', minHeight: '85vh', flexDirection: 'column',background:'white' }} >
            {loading ? <FullScreenLoader /> : <><button className="btn btn-primary" style={{ cursor: "pointer" }} onClick={handleReset} disabled={loading}>
                Reset Uploaded Data
            </button>
                {successMsg}</>}
        </div>
    );
}

export default Settings;