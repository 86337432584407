import { API } from "aws-amplify"

export const getAllTCs = () => {
    return API.get('baseURL', `/ae/transformer-centres`)
}

export const addTCReading = (transformerId,body) => {
    return API.post('baseURL', `/ae/transformer-centres/${transformerId}/add-reading`,{
        body
    })
}